import { FC } from 'react';
import styled from 'styled-components';

import { Icon, Flex, SystemProps } from '@storyofams/react-ui';
import { Button } from '~components';
import { useNav } from '~context';
import { Hamburger } from '../../Icons';

import { Logo } from './Logo';
import { MenuItemsWrapper } from './MenuItemsWrapper';
import { MobileBackdrop } from './MobileBackdrop';

const StyledNavigationWrapper = styled(Flex)`
  position: fixed;
  width: 100%;
  alignItems: start;
  padding: 0 16px;
  background: linear-gradient(180deg, ${props => props.theme.colors.grey900} 0%, ${props => props.theme.colors.grey900}AA 50%, transparent 100%);
  transition: background 0.5s ease-in-out;

  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 24px;
  }
`;

interface NavigationPrimaryProps extends SystemProps {
  hasSearchButton?: boolean;
  disableLink: boolean;
}

export const NavigationPrimary: FC<NavigationPrimaryProps> = ({
  children,
  hasSearchButton,
  disableLink,
  ...props
}) => {
  const { mobileMenuOpen, setMobileMenuOpen } = useNav();

  return (
    <>
      <StyledNavigationWrapper
        as="nav"
        {...props}
      >
        <Logo disableLink={disableLink} />

        <Button
          variant="unstyled"
          onClick={() => setMobileMenuOpen(true)}
          display={['flex', 'flex', 'flex', 'flex', 'none!important']}
          mt={2}
          ml="auto"
        >
          <Icon icon={Hamburger} fontSize={3}  color={"white"} />
        </Button>

        <MobileBackdrop
          onClick={() => setMobileMenuOpen(false)}
          right={0}
          opacity={mobileMenuOpen ? 1 : 0}
          display={mobileMenuOpen ? ['flex', 'flex', 'flex', 'flex', 'none'] : 'none'}
        />

        <MenuItemsWrapper>{children}</MenuItemsWrapper>
      </StyledNavigationWrapper>
    </>
  );
};
