import { Box, Flex, SystemProps } from '@storyofams/react-ui';

import { Button } from '../Button';
import { RegionSelect } from '../Forms';
import { Heading } from '../Heading';
import { LanguageSwitch } from '../LanguageSwitch';
import { Modal } from '../Modal';

import { Text } from '../Text';
import { useEffect, useMemo, useState } from 'react';
import { Select } from '../Select';
import { useRouter } from 'next/router';

interface ShopModalProps extends SystemProps {
  isOpen?: boolean;
  title?: string;
  description: string;
  button: string;
  onClose(): void;
}


const publicShopCountries = process.env.NEXT_PUBLIC_SHOP_COUNTRIES
const countryArrayLabel = {
  'de-at': 'Österreich',
  'nl-be': 'België',
  'de-de': 'Deutschland',
  'it-it': 'Italia',
  'dk-dk': "Danmark",
  'es-es': "España",
  'fi-fi': "Suomi",
  'fr-fr': "France",
  'nl-nl': 'Nederland',
  'se-se': "Sverige",
};

export const ShopModal = ({isOpen, title, description, button, onClose}: ShopModalProps) => {
  const { locale } = useRouter();
  let countryArray = []
  
  if (
    typeof publicShopCountries === 'string' &&
    publicShopCountries !== '' &&
    publicShopCountries.includes(",")
  ) {
    countryArray = publicShopCountries.split(',')
  }

  const [lang, setLang] = useState('nl');
  const options = useMemo(() => {
    return countryArray.map((country) => {
      return {
        label: countryArrayLabel[country],
        value: country,
      };
    });
  }, []);

  useEffect(() => {
    if (locale) {
      const lang = locale.split('-')[0];
      setLang(lang);
    }
  }, []);
  
  return (
    <Modal p={0} isOpen={isOpen} close={onClose} hideCloseButton>
      <Flex
        height={[88, 88, 160]}
        css={{
          background: 'linear-gradient(93.67deg, #729186 0%, #556E65 100%)',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          as="img"
          src="/images/shield-white.png"
          width="76px"
          height="auto"
        />
      </Flex>

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        px={[5, 5, 6]}
        pt={4}
        pb={[5, 5, 6]}
      >
        <Box width="100%" maxWidth={360}>
          {title && (
            <Heading
              as="h2"
              variant="h4"
              mb={[1.5, 1.5, 2]}
              fontWeight="bold"
              textAlign="center"
            >
              {title}
            </Heading>
          )}

          <Text mb={[3, 3, 4]} textAlign="center">
            {description}
          </Text>

          <Select options={options} onChange={(val) => setLang((val as any).value)} mb={3} />

          <Button width="100%" href={`https://www.shop.mycreation.lighting.philips.com/${lang}`} value={lang} onClick={onClose} variant="primary" target='_blank'>
            {button}
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
};
