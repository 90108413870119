import React, { ReactNode } from 'react';
import { Box, Flex, Icon, system } from '@storyofams/react-ui';
import Swiper, { ReactIdSwiperProps } from 'react-id-swiper';
import styled from 'styled-components';
import SwiperCore, { Mousewheel, Navigation } from 'swiper';

import { Button, ButtonProps } from '../Button';
import { ArrowNarrowLeft } from '../Icons';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { Container } from '~components';

SwiperCore.use([Mousewheel, Navigation]);

export type SliderProps = {
  children: ReactNode[];
  sliderType?: string;
} & ReactIdSwiperProps;

/* this calculation evens out spacing with marginX="auto", so all DynamicSectionContainer
 have same spacing without using margin(which cuts of slider items); */
const lg = 'calc(130px + (max(100%, 1440px) - 1440px) / 2)';
export const sliderPadding = { all: 2, sm: 5, md: 10, lg };
export const SliderContainer = ({ children, ...props }) => (
  <Container
    overflow="hidden"
    mx={0}
    pt={[5, 5, 8, 100]}
    pb={[5, 5, 8, 100]}
    px={0}
    {...props}
  >
    {children}
  </Container>
);

export const SliderWrapper = styled(Flex).attrs({
  overflow: 'hidden',
  width: '100%',
  variant: 'center',
})`
  ${system}
`;

export const Slider = ({ children, sliderType = 'slider', ...props }: SliderProps) => {
  
  return (
    <SliderWrapper px={sliderType === 'scrollbar' ? sliderPadding : sliderType === 'usps' ? sliderPadding : 0}>
      <SwiperContainer>
        <Swiper
          slidesPerView="auto"
          spaceBetween={24}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          renderPrevButton={() => sliderType !== 'usps' ? <NavigationButton/> : null}
          renderNextButton={() => sliderType !== 'usps' ? <NavigationButton direction="next"/> : null}
          direction="horizontal"
          mousewheel={false}
          watchOverflow
          {...props}
        >
          {children?.map((child, index) => (
            <Box key={String(index)} display="block" className="swiper-slide">
              {child}
            </Box>
          ))}
        </Swiper>
      </SwiperContainer>
    </SliderWrapper>
  )
};

export type SlideProps = {
  title: String;
  height: any;
} & Omit<ButtonProps, 'children'>;

export const Slide = ({ title, height, ...props }: SlideProps) => (
  <Button {...props} height={height} variant="unstyled">
    {title}
  </Button>
);

const NavigationButton = ({ direction = 'prev' }) => (
  <Box
    borderRadius="full"
    width="42px"
    height="42px"
    bg="secondary900"
    className={`swiper-button-${direction}`}
    display={['flex']}
  >
    <Icon
      icon={ArrowNarrowLeft}
      fontSize={2.25}
      color="white"
      css={{
        transform: `rotate(${direction === 'next' ? '180deg' : '0deg'})`,
      }}
    />
  </Box>
);

const SwiperContainer = styled.div`
  width: 100%;

  & .swiper-wrapper {
    min-width: 100%;
  }

  & .swiper-container {
    overflow: inherit;

    .swiper-slide {
      width: max-content !important;
    }

    .swiper-button-next,
    .swiper-button-prev {
      transition: opacity 0.25s ease-in-out;

      &:after {
        content: unset;
      }
    }

    .swiper-button-lock {
      opacity: 0;
    }

    .swiper-button-next {
      right: 0;
    }
  }
`;