import { FC, useEffect, createContext, useContext, useState } from 'react';

export interface NavContextType {
  mobileMenuOpen: boolean;
  setMobileMenuOpen: (val: boolean) => void;
  category: string | null;
  setCategory: (val: string | null) => void;
}

export const NavContext = createContext<NavContextType>(undefined);

export const NavProvider: FC = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    if (!mobileMenuOpen) {
      setCategory(null);
    }
  }, [mobileMenuOpen]);

  return (
    <NavContext.Provider
      value={{
        mobileMenuOpen,
        setMobileMenuOpen,
        category,
        setCategory,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const useNav = () => {
  const ctx = useContext(NavContext);

  if (!ctx) {
    throw new Error('Nav context must be used within a nav context provider');
  }

  return ctx;
};
