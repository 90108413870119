import { render } from '@testing-library/react';
import { renderHook as renderHookBase } from '@testing-library/react-hooks/dom';
import userEvent from '@testing-library/user-event';

import { TestProviders } from '~components';

const customRender = (ui, options?) =>
  render(ui, { wrapper: TestProviders, ...options });

export const renderHook = (hook, options?) =>
  renderHookBase(hook, { wrapper: TestProviders, ...options });

// re-export everything
export * from '@testing-library/react';

// override render method
export { customRender as render, userEvent };
