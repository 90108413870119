import { ReactNode } from 'react';
import { Box, Stack, SystemProps } from '@storyofams/react-ui';
import { css } from 'styled-components';

export const HorizontalScroll = ({
  children,
  ...props
}: { children: ReactNode } & SystemProps) => (
  <Box maxWidth="100%" position="relative">
    <Box
      display={['block', 'block', 'none']}
      position="absolute"
      right={0}
      top={0}
      bottom={0}
      width={40}
      zIndex={1}
      css={css({
        background:
          'linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)',
      })}
    />

    {/* @ts-ignore */}
    <Stack space={3} maxWidth="100%" overflowX="auto" pb={[3, 3, 0]} {...props}>
      {children}
    </Stack>
  </Box>
);
