import { Box, SystemProps } from "@storyofams/react-ui";

import { FooterItem } from "~lib/storyblok/sdk";

import { Heading } from "../Heading";
import HubspotForm from "react-hubspot-form";
import { Text } from "../Text";
import { Container } from "~components";
import styled from "styled-components";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

export const Newsletter = ({
  newsletter_title,
  newsletter_description,
  newsletter_placeholder,
  newsletterHubspotPortalId,
  newsletterHubspotFormId,
  ...props
}: Partial<FooterItem["content"]> & SystemProps) => {
  const router = useRouter();
  const newsletterRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setTimeout(() => {
      const [, hash] = router.asPath.split("#");
      if (hash === "newsletter") {
        newsletterRef?.current?.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 250);
  }, []);

  return (
    <Box {...props} id="newsletter" ref={newsletterRef}>
      <Heading
        variant="h3"
        as="h4"
        mb={[2, 2, 3]}
        textAlign="center"
        fontWeight="medium"
        color="white"
      >
        {newsletter_title}
      </Heading>
      
      <Text
        variant="l"
        mx="auto"
        mb={[3, 3, 4]}
        maxWidth={[343, 500]}
        textAlign="center"
        color="white"
      >
        {newsletter_description}
      </Text>
      
      <StyledNewsletterContainer>
        <HubspotForm
          portalId={newsletterHubspotPortalId}
          formId={newsletterHubspotFormId}
          loading={<Container><Text variant="l">Loading...</Text></Container>}
        />
      </StyledNewsletterContainer>
    </Box>
  );
};

const StyledNewsletterContainer = styled(Box)`
  form {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    padding-left: ${(p) => p.theme.space[2.5]}px;
    padding-right: ${(p) => p.theme.space[2.5]}px;
    width: 300px;
    height: 44px;
    border: 1px solid;
    border-color: ${(p) => p.theme.colors.white};
    border-radius: ${(p) => p.theme.radii.full};
    position: relative;

    .hs-email {
      div.input {
        height: 100%;
      }

      input {
        height: 100%;
        font-size: 14px;
        line-height: 44;
        background: transparent;
        border: none;
        color: white;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: white;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: ${(p) => p.theme.colors.white};
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: ${(p) => p.theme.colors.white};
        }
      }
    }

    input[type='submit'] {
      position: relative;
      appearance: none;
      text-decoration: none;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      margin: 11px;
      font-size: 0;
      background: url('/images/arrow-narrow-right.svg');
      cursor: pointer;

      border: none;
    }

    .hs-error-msgs {
      display: block;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      list-style: none;

      li {
        background: ${(p) => p.theme.colors.primary500};
        width: 300px;
        height: 44px;
        text-align: center;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: ${(p) => p.theme.radii.full};

        label {
          color: ${(p) => p.theme.colors.white};
        }

      }
    }
  }

  .submitted-message {
    text-align: center;
    color: ${(p) => p.theme.colors.white};
    font-size: ${(p) => p.theme.fontSizes[2.5]}px;
    font-family: ${(p) => p.theme.fonts.body};
    padding: ${(p) => p.theme.space[2.5]}px;
  }

`;
