import { Box } from '@storyofams/react-ui';
import styled from 'styled-components';

export const MobileBackdrop = styled(Box).attrs({
  position: ['fixed', 'fixed', 'fixed', 'fixed', 'static'],
  top: [0, 0, 0, 0, 'unset'],
  bottom: [0, 0, 0, 0, 'unset'],
  width: '100%',
  bg: 'rgba(0, 0, 0, .75)',
  opacity: 0.5,
  zIndex: 'overlay',
  transition: 'opacity .24s ease-out',
})``;
