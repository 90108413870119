import { Box, SystemProps, system } from '@storyofams/react-ui';
import styled from 'styled-components';
import { variant } from 'styled-system';

export const textVariants = {
  xs: {
    fontSize: 1.5,
  },
  s: {
    fontSize: 2,
    lineHeight: 1.5,
  },
  m: {
    lineHeight: ['high', '160%'],
    fontSize: [1.75, 2.25],
  },
  l: {
    fontSize: [2, '23px'],
  },
  inputLabel: {
    fontSize: 1.5,
    color: '#000000',
    opacity: 0.75,
  },
  checkboxLabel: {
    fontSize: [1.5, 1.75],
    color: '#000000',
  },
};

type CustomProps = {
  /** defaults to `p` */
  as?:
    | 'p'
    | 'span'
    | 'blockquote'
    | 'strong'
    | 'em'
    | 'pre'
    | 'label'
    | 'a'
    | 'li';
  variant?: keyof typeof textVariants;
} & SystemProps;

export const Text = styled(Box).attrs((props: CustomProps) => ({
  as: props?.as ?? 'p',
  variant: props?.variant || 'm',
}))<CustomProps>`
  font-family: ${(p) => p.theme.fonts.body};
  color: ${(p) => p.theme.colors.grey700};
  line-height: ${(p) => p.theme.lineHeights.high};
  letter-spacing: -0.01em;

  ${variant({ variants: textVariants })}
  ${system}
`;
