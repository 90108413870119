import { FC } from 'react';
import { Stack, Box, css, SystemProps } from '@storyofams/react-ui';

import {
  render,
  MARK_BOLD,
  MARK_LINK,
  NODE_PARAGRAPH,
  NODE_HEADING,
  NODE_OL,
  NODE_UL,
  NODE_LI,
} from 'storyblok-rich-text-react-renderer';
import { Heading } from '../Heading';
import { Text, textVariants } from "../Text";

interface RichTextProps extends SystemProps {
  text: any;
  markResolvers?: any;
  nodeResolvers?: any;
  variant?: keyof typeof textVariants;
  textColor?: string;
}

export const RichText: FC<RichTextProps> = ({
  markResolvers = {},
  nodeResolvers = {},
  text,
  variant = "m",
  textColor = 'grey700',
  ...props
}) => {
  return (
    <Box color="grey800" css={css({ '> *:last-child': { pb: 0 } })} {...props}>
      {render(text, {
        markResolvers: {
          [MARK_BOLD]: (children) => <b>{children}</b>,
          [MARK_LINK]: (children, props) => {
            const { href } = props;
            return (
              <Box textDecoration="underline" as="span" color="secondary900">
                <a href={href} target="_blank" rel="noopener noreferrer">
                  {children}
                </a>
              </Box>
            );
          },
          ...markResolvers,
        },
        nodeResolvers: {
          [NODE_HEADING]: (children, { level }) => (
            <Heading
              as={`h${level}` as any}
              mb={[2, 2, 5]}
              variant={`h${level}` as any}
            >
              {children}
            </Heading>
          ),
          [NODE_PARAGRAPH]: (children) => (
            <Text variant={variant} color={textColor} pb={[2, 2, 3]} as="p">
              {children}
            </Text>
          ),
          [NODE_OL]: (children) => (
            <Stack
              space={[1, 1, 2]}
              // @ts-ignore
              as="ol"
              pl={2}
              pb={[2, 2, 3]}
              flexDirection="column"
            >
              {children}
            </Stack>
          ),
          [NODE_UL]: (children) => (
            <Stack
              space={0}
              pl={2}
              pb={[2, 2, 3]}
              flexDirection="column"
              // @ts-ignore
              as="ul"
            >
              {children}
            </Stack>
          ),
          [NODE_LI]: (children) => (
            <Text
              as="li"
              css={css({ p: { pb: 0 }, '&::marker': { fontSize: 14 } })}
            >
              {children}
            </Text>
          ),
          ...nodeResolvers,
        },
      })}
    </Box>
  );
};
