import React, { forwardRef, Ref, useState, useEffect } from 'react';

import { Icon, Box } from '@storyofams/react-ui';
import { pick, omit } from '@styled-system/props';
import { useId } from 'react-id-generator';
import type { PolymorphicForwardRefExoticComponent } from 'react-polymorphic-types';
import styled from 'styled-components';
import { InputWrapper, InputWrapperProps } from '~components/common/Forms';
import { Eye } from '~components/common/Icons';

const _defaultElement = 'input';

type InputProps = {
  icon?: any;
  id?: string;
  disabled?: boolean;
  type?: string;
} & Partial<InputWrapperProps>;

const StyledInput = styled(Box)<{ error?: string }>`
  appearance: none;
  display: inline-block;
  background-color: white;
  border-radius: ${({ theme }) => theme.radii.xs};

  min-height: 44px;
  width: 100%;

  border: 1px solid
    ${({ error, theme }) => (error ? theme.colors.error : '#ced4da')};

  transition: border-color 0.18s ease-in-out, background-color 0.18s ease-in-out;

  text-decoration: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.grey500};
    text-overflow: ellipsis;
  }

  &:not(:disabled) {
    &:hover,
    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.colors.secondary900};
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
`;

export const Input: PolymorphicForwardRefExoticComponent<
  InputProps,
  typeof _defaultElement
> = forwardRef(({ icon, ...props }: InputProps, ref: Ref<HTMLInputElement>) => {
  const { label, status, statusMessage, error, disabled, id: givenId } = props;
  const autoId = useId();
  const id = givenId || `input-${autoId}`;
  const [type, setType] = useState('text');

  useEffect(() => {
    if (props?.type) {
      setType(props?.type);
    }
  }, [props?.type]);

  return (
    <InputWrapper
      {...pick(props)}
      label={label}
      statusMessage={statusMessage}
      status={status}
      error={error}
      id={id}
    >
      <Box position="relative">
        <StyledInput
          as={_defaultElement}
          id={id}
          ref={ref}
          disabled={disabled}
          px={2}
          py={1.5}
          pr={icon && 5}
          fontSize={1.75}
          lineHeight="high"
          letterSpacing="-0.01em"
          color="black"
          borderRadius="xs"
          {...omit(props)}
          type={type}
        />

        {(!!icon || props?.type === 'password') && (
          <Icon
            display="flex"
            alignItems="center"
            position="absolute"
            right={1.5}
            top={0}
            bottom={0}
            opacity={disabled ? 0.6 : 1}
            color="grey500"
            fontSize={3}
            {...(props.type === 'password'
              ? {
                  icon: Eye,
                  onClick: () =>
                    setType(type === 'password' ? 'text' : 'password'),
                  as: 'button',
                  type: 'button',
                }
              : {
                  pointerEvents: 'none',
                })}
            {...(icon ? (icon?.icon ? icon : { icon }) : {})}
          />
        )}
      </Box>
    </InputWrapper>
  );
});
