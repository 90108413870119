import { FC, createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import storyblokSDK from '~lib/storyblok/client';
import { TranslationsItem } from '~lib/storyblok/sdk';

export interface TranslationsContextType {
  translations: TranslationsItem['content'];
  showSettingsModal: boolean;
  hasLanguageSettings: boolean;
  regionalLanguages: string[];
  
  region: string;
  setRegion(val: string): void;
  language: string;
  setLanguage(val: string): void;
  languageSelectorOpen: boolean;
  setLanguageSelectorOpen(val: boolean): void;

  saveSettings(): void;
}

export const TranslationsContext =
  createContext<TranslationsContextType>(undefined);

export const TranslationsProvider: FC = ({ children }) => {
  const { defaultLocale, locale, locales, asPath, ...router } = useRouter();
  const [lang, ] = locale.split('-');

  // Translations
  const fetchTranslations = async () => {
    const data = (
      await storyblokSDK(2).translations({
        slug: `${lang}/settings/translations`
      })
    ).TranslationsItem;

    return data?.content;
  };
  const { data: translations } = useQuery(['translations'], fetchTranslations);

  // Language switch
  const [region, setRegion] = useState('eu');
  const [language, setLanguage] = useState('en');
  const [hasLanguageSettings, setHasLanguageSettings] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);

  useEffect(() => {
    const storageRegion = localStorage.getItem('LOCAL_STORAGE_REGION');
    const storageLanguage = localStorage.getItem('LOCAL_STORAGE_LANGUAGE');

    if (storageRegion === "pc") {
      localStorage.setItem('LOCAL_STORAGE_REGION', 'anz')
      setRegion('anz')
    }
    
    if (typeof storageRegion === 'string' && storageRegion === 'null') {
      localStorage.setItem('LOCAL_STORAGE_REGION', '')
    }
  
    if (typeof storageLanguage === 'string' && storageLanguage === 'null') {
      localStorage.setItem('LOCAL_STORAGE_LANGUAGE', '')
    }

    if (storageRegion) setRegion(storageRegion);
    if (storageLanguage) setLanguage(storageLanguage);

    if (!storageRegion || !storageLanguage) return setShowSettingsModal(true);
  }, []);

  const saveSettings = useCallback(() => {
    if (language === 'ccm')
      return router.push('https://www.3dprinted.lighting.lightolier.com/en/us/')
    if (region === 'na')
      return router.push(`https://www.3dprinted.lighting.lightolier.com/${language}/us/`)

    localStorage.setItem('LOCAL_STORAGE_LANGUAGE', language);
    localStorage.setItem('LOCAL_STORAGE_REGION', region);

    setHasLanguageSettings(true);

    if (showSettingsModal) {
      setShowSettingsModal(false);
    }

    if (languageSelectorOpen) {
      setLanguageSelectorOpen(false);
    }

    router.push(asPath, asPath, { locale: `${language}-${region}` });
  }, [language, region, showSettingsModal, languageSelectorOpen]);

  const languageOptions = useMemo(() => {
    const regionLanguages = locales
      .map((locale) => locale.split('-'))
      .filter((locale) => locale[1] === region)
      .map((locale) => locale[0]).sort()
    if (!regionLanguages.includes(language)) setLanguage(regionLanguages[0])
    return regionLanguages || [];

  }, [region]);

  return (
    <TranslationsContext.Provider
      value={{
        translations,
        region,
        setRegion,
        language,
        setLanguage,
        saveSettings,
        hasLanguageSettings,
        setLanguageSelectorOpen,
        languageSelectorOpen,
        showSettingsModal,
        regionalLanguages: languageOptions
      }}
    >
      {children}
    </TranslationsContext.Provider>
  );
};

export const useTranslations = () => {
  const ctx = useContext(TranslationsContext);

  if (!ctx) {
    throw new Error(
      'Translations context must be used within a Translations context provider',
    );
  }

  return ctx;
};
