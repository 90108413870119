import { LangmodalItem } from '~lib/storyblok/sdk';

import { Button } from '../Button';
import { Drawer } from '../Drawer';
import { RegionSelect } from '../Forms';
import { LanguageSwitch } from '../LanguageSwitch';

interface LanguageDrawerProps {
  isOpen?: boolean;
  setIsOpen(val: boolean): void;
  saveSettings(): void;
}

export const LanguageDrawer = ({
  isOpen,
  setIsOpen,
  saveSettings,
  drawer_title,
  region_input,
  language_input,
  drawer_button,
}: LanguageDrawerProps & LangmodalItem['content']) => {

  return (
    <Drawer
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      heading={drawer_title}
    >
      <RegionSelect mb={3} label={region_input} />

      <LanguageSwitch label={language_input} />

      <Button mt={4} onClick={saveSettings}>
        {drawer_button}
      </Button>
    </Drawer>
  );
};
