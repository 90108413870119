import { GraphQLClient } from 'graphql-request';

import { getSdk } from './sdk';
import { storyblokThrottle } from './throttle';

const storyblokSDK = (points: number) => {
  const sdk = getSdk(client, (method) => {
    return storyblokThrottle(method, points);
  });
  return sdk;
};

export const version = () =>
  process.env.PASSWORD_PROTECT || process.env.NODE_ENV === "development"
    ? "draft"
    : "published";

export const client = new GraphQLClient("https://gapi.storyblok.com/v1/api", {
  headers: {
    Version: version(),
    Token: process.env.NEXT_PUBLIC_STORYBLOK_TOKEN,
  },
});

export default storyblokSDK;
