import { Box, Flex, Icon } from '@storyofams/react-ui';
import { Button, Heading } from '~components';
import { useNav } from '~context';
import { ChevronLeft, Cross } from '~components/common/Icons';

export const MenuItemsWrapper = ({ children, ...props }) => {
  const { mobileMenuOpen, setMobileMenuOpen, category, setCategory } = useNav();
  
  return (
    <Box
      flex={1}
      position={['fixed', 'fixed', 'fixed', 'fixed', 'static']}
      top={[0, 0, 0, 0, 'unset']}
      bottom={[0, 0, 0, 0, 'unset']}
      right={[
        mobileMenuOpen ? 0 : '-100%',
        mobileMenuOpen ? 0 : '-100%',
        mobileMenuOpen ? 0 : '-100%',
        mobileMenuOpen ? 0 : '-100%',
        'unset',
      ]}
      bg={['white', 'white', 'white', 'white', 'transparent']}
      zIndex={['overlay', 'overlay', 'overlay', 'overlay', 0]}
      width="100%"
      maxWidth={['308px', '308px', '308px', '308px', 'none']}
      transition="right .24s ease-out"
    >
      <Flex
        display={['flex', 'flex', 'flex', 'flex', 'none !important']}
        height={[64, 64, 64, 64, 'auto']}
        bg="white"
        boxShadow="0px 4px 16px rgba(0, 0, 0, 0.05)"
        position="relative"
        alignItems="center"
        px={2}
        zIndex={[1301, 1301, 1301, 1301, 0]}
      >
        <Flex alignItems="center" justifyContent="flex-start" width="100%">
          {category && (
            <Button
              variant="unstyled"
              onClick={() => setCategory(null)}
              left={2}
              position="absolute"
            >
              <Icon icon={ChevronLeft} fontSize={3} color="grey800"/>
            </Button>
          )}
          
          <Heading
            variant="h4"
            as="h4"
            fontSize={2}
            fontWeight="bold"
            width="100%"
            textAlign="center"
          >
            {category || 'Menu'}
          </Heading>
        </Flex>
        
        <Button
          onClick={() => setMobileMenuOpen(false)}
          variant="unstyled"
          alignItems="center"
          justifyContent="flex-end"
          width="40px"
          height="40px"
          position="absolute"
          right={2}
        >
          <Icon fontSize={3} icon={Cross} color="grey800"/>
        </Button>
      </Flex>
      
      {children}
    </Box>
  );
};
