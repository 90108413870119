import { ReactNode, CSSProperties } from 'react';
import { Stack, Box, SystemProps } from '@storyofams/react-ui';
import { ResponsiveValue } from 'styled-system';
import styled from 'styled-components';


// Add deprecated warning
/**
 * @deprecated
 */
type ContainerProps = {
  children: ReactNode;
  as?: 'section' | 'footer';
  withOverflowHidden?: boolean;
  bg?: SystemProps['bg'];
  space?: ResponsiveValue<CSSProperties['margin']>;
  flexDirection?: any;
  id?: string;
} & Omit<SystemProps, 'flexDirection'>;


// Add deprecated warning
/**
 * @deprecated
 */
export const containerPadding = { all: 2, sm: 6, md: 8, lg: 15 };


// Add deprecated warning
/**
 * @deprecated
 */
const ContainerBox = ({
  children,
  // When setting up: change the space according to the design
  space = [3, 5],
  flexDirection = 'column',
  ...props
}: ContainerProps) => {
  return (
    <Stack
      maxWidth="maxWidth"
      px={containerPadding}
      mx="auto"
      width="100%"
      space={space}
      flexDirection={flexDirection}
      {...props}
    >
      {children}
    </Stack>
  );
};

// Add deprecated warning
/**
 * @deprecated
 */
export const LegacyContainer = ({
  children,
  as,
  bg,
  withOverflowHidden = false,
  ...props
}: ContainerProps) => {
  if (withOverflowHidden || bg) {
    return (
      <Box overflowX="hidden" bg={bg} as={as}>
        <ContainerBox {...props}>{children}</ContainerBox>
      </Box>
    );
  }

  return (
    <ContainerBox as={as} {...props}>
      {children}
    </ContainerBox>
  );
};

export const Container = styled(Box)`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 12px;
  padding-right: 12px;

  @media (min-width: ${({theme}) => theme.breakpoints.sm}) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.md}) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media (min-width: ${({theme}) => theme.breakpoints.lg}) {
    padding-left: 130px;
    padding-right: 130px;
  }
`;