import { FC, createContext, useContext, useState, useEffect, useCallback } from 'react';

export interface QuotationContextType {
  quotation: number;
  setQuotation: (val: number) => void;
}

export const QuotationContext = createContext<QuotationContextType>(undefined);

export const QuotationProvider: FC = ({ children }) => {
  const [quotation, setQuotation] = useState<number>(0);
  
  useEffect(() => {
    const quotation = localStorage.getItem('quotation');
    if (quotation) {
      setQuotation(JSON.parse(quotation));
    }
  }, []);

  const saveQuotation = useCallback((val: number) => {
    setQuotation(val);
    localStorage.setItem('quotation', JSON.stringify(val));
  }, []);

  return (
    <QuotationContext.Provider
      value={{
        quotation,
        setQuotation: saveQuotation,
      }}
    >
      {children}
    </QuotationContext.Provider>
  );
};

export const useQuotation = () => {
  const ctx = useContext(QuotationContext);

  if (!ctx) {
    throw new Error('quotation context must be used within a nav context provider');
  }

  return ctx;
};
