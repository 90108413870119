import { Box, Flex, SystemProps } from '@storyofams/react-ui';

import { LangmodalItem } from '~lib/storyblok/sdk';

import { Button } from '../Button';
import { RegionSelect } from '../Forms';
import { Heading } from '../Heading';
import { LanguageSwitch } from '../LanguageSwitch';
import { Modal } from '../Modal';

import { Text } from '../Text';

interface LanguageModalProps extends SystemProps {
  isOpen?: boolean;
  saveSettings(): void;
}

export const LanguageModal = ({
  isOpen,
  saveSettings,
  modal_button,
  modal_description,
  modal_title,
  language_input,
  region_input,
}: LanguageModalProps & LangmodalItem['content']) => {
  return (
    <Modal p={0} isOpen={isOpen} overflowY={'scroll'}>
      <Flex
        height={[88, 88, 160]}
        css={{
          background: 'linear-gradient(93.67deg, #729186 0%, #556E65 100%)',
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          as="img"
          src="/images/shield-white.png"
          width="76px"
          height="auto"
        />
      </Flex>

      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        px={[5, 5, 6]}
        pt={4}
        pb={[5, 5, 6]}
      >
        <Box width="100%" maxWidth={360}>
          <Heading
            as="h2"
            variant="h4"
            mb={[1.5, 1.5, 2]}
            fontWeight="bold"
            textAlign="center"
          >
            {modal_title}
          </Heading>

          <Text mb={[3, 3, 4]} textAlign="center">
            {modal_description}
          </Text>

          <RegionSelect label={region_input} mb={3} />

          <LanguageSwitch label={language_input} mb={[4, 4, 5]} />

          <Button width="100%" onClick={saveSettings}>
            {modal_button}
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
};
