import React, { ReactNode } from 'react';
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
  DialogOverlayProps,
} from '@reach/dialog';
import { Icon, Flex, SystemProps } from '@storyofams/react-ui';
import { pick, omit } from '@styled-system/props';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Heading } from '~components';
import { Button } from '../Button';
import { Close } from '../Icons';

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDrawer = motion(ReachDialogContent);

const Overlay = styled(MotionOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: ${(p) => p.theme.zIndices.modal};

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.4);
`;

const Content = styled(MotionDrawer)<
  SystemProps & { children: ReactNode; from: 'right' | 'left' }
>`
  position: fixed;
  ${(p) => (p.from === 'left' ? 'left: 0' : 'right: 0')};
  top: 0;
  bottom: 0;

  flex: 1;

  width: 308px;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    width: 402px;
  }
  height: 100vh;

  padding: 0;
  margin: 0;

  background: transparent;
`;

const ContentWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: ${(p) => p.theme.colors['white']};
  z-index: ${(p) => p.theme.zIndices.modal + 1};

  &:after {
    content: '';
    position: fixed;
    top: 0;
    width: 308px;
    height: ${(p) => p.theme.space[4]}px;
    background-color: ${(p) => p.theme.colors['white']};
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    &:after {
      width: 402px;
    }
  }
`;

interface Props extends DialogOverlayProps, SystemProps {
  isOpen: boolean;
  close(any): void;
  heading?: string | ReactNode;
  ariaLabel?: string;
  children?: ReactNode;
  header?: ReactNode;
  from?: 'right' | 'left';
}

export const Drawer = ({
  children,
  ariaLabel,
  heading,
  isOpen,
  close,
  from = 'right',
  bg,
  header,
  ...props
}: Props) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <Overlay
          onDismiss={close}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: 'easeInOut', duration: 0.24 }}
          {...omit(props)}
        >
          <Content
            aria-label={ariaLabel || 'modal'}
            initial={{ x: from === 'left' ? '-100%' : '100%' }}
            exit={{ x: from === 'left' ? '-100%' : '100%' }}
            animate={{ x: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.24 }}
            from={from}
          >
            <ContentWrapper
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.32 }}
            >
              <Flex
                flexDirection="column"
                height="100%"
                width="100%"
                {...pick(props)}
              >
                <Flex
                  position="sticky"
                  top={0}
                  flexShrink={0}
                  flexDirection="column"
                  px={[3]}
                  pt={[3]}
                  zIndex="modal"
                  bg="white"
                >
                  <Flex
                    flex={1}
                    alignItems="center"
                    justifyContent={heading ? 'space-between' : 'flex-end'}
                    position="relative"
                    pt={4}
                    pb={2}
                  >
                    {heading && (
                      <>
                        {typeof heading === 'string' ? (
                          <Heading
                            as="h2"
                            variant="h5"
                            fontSize={[2, 2, 2, 2.25]}
                            fontWeight="bold"
                            lineHeight="high"
                            flex={1}
                            color="secondary900"
                          >
                            {heading}
                          </Heading>
                        ) : (
                          heading
                        )}
                      </>
                    )}

                    <Button
                      position="absolute"
                      top={'-4px'}
                      right={'-4px'}
                      variant="unstyled"
                      onClick={close}
                      aria-label="Close modal"
                    >
                      <Icon icon={Close} fontSize={2.5} color="grey800" />
                    </Button>
                  </Flex>
                </Flex>

                <Flex flex={1} flexDirection="column" px={3} pb={5} pt={[3, 3, 4]}>
                  {children}
                </Flex>
              </Flex>
            </ContentWrapper>
          </Content>
        </Overlay>
      )}
    </AnimatePresence>
  );
};
