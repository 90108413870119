import { ReactNode } from 'react';

import {
  InputWrapperProps as DefaultInputWrapperProps,
  Box,
  StatusMessage,
} from '@storyofams/react-ui';
import { pick } from '@styled-system/props';
import { Text } from '~components/common/Text';

export type InputWrapperProps = DefaultInputWrapperProps & {
  disabled?: boolean;
  children: ReactNode;
};

export const InputWrapper = ({
  label,
  status,
  statusMessage,
  error,
  disabled,
  id,
  children,
  ...props
}: InputWrapperProps) => {
  return (
    <Box {...pick(props)}>
      {label && (
        <Text as="label" variant="inputLabel" mb={1} htmlFor={id}>
          {label}
        </Text>
      )}

      {children}

      {(!!statusMessage || error) && (
        <StatusMessage
          status={error ? 'error' : status}
          mt={1}
          color={error ? 'error' : 'grey700'}
          fontSize={1.5}
          lineHeight="high"
          letterSpacing="-0.01em"
          fontWeight="regular"
        >
          {error || statusMessage}
        </StatusMessage>
      )}
    </Box>
  );
};
