import { Box } from '@storyofams/react-ui';
import styled from 'styled-components';

export const Badge = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.error};
  color: ${(p) => p.theme.colors.grey100};
  border-radius: 99px;
  padding: ${(p) => p.theme.space[0.5]}px ${(p) => p.theme.space[1]}px;

  font-weight: 400;
  font-size: ${(p) => p.theme.fontSizes[1.5]}px;
  line-height: 130%;
  margin-left: ${(p) => p.theme.space[1.5]}px;
  width: 20px;
  height: 20px;
`;
