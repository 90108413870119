import {
  useRef,
  useState,
  Children,
  ReactNode,
  cloneElement,
  isValidElement,
} from 'react';
import { Box, Flex, Icon, Close, Stack, ChevronUp } from '@storyofams/react-ui';
import { useClickAway, useMeasure } from 'react-use';

import { useTranslations } from '~context';
import { Button } from '~components/common/Button';
import { Checkbox } from '~components/common/Checkbox';
import { Divider } from '~components/common/Divider';
import { Heading } from '~components/common/Heading';
import { LegacyContainer } from '~components/common/Layout/Container';
import { Filter, FilterProps } from './Filter';

export type FiltersProps = {
  filters: { [index: string]: string[] };
  /** onChange handler, most likely you'll want the URL state to be updated */
  onChange: (filters: { [index: string]: string[] }) => void;
  onReset?: () => void;
  children: ReactNode;
};

export const Filters = ({
  filters,
  children,
  onReset,
  onChange,
}: FiltersProps) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [drawerRef, { height }] = useMeasure();
  const [openFilter, setOpenFilter] = useState<FilterProps>(undefined);
  const { translations } = useTranslations();

  useClickAway(ref, () => {
    setIsOpen(false);
  });

  const handleChange = ({ value }: { label: string; value: string }) => {
    let update = [...(filters[openFilter.value] ?? []), value];

    if (filters[openFilter.value]?.includes(value)) {
      update = filters[openFilter.value].filter((filter) => filter !== value);
    }

    onChange({ ...filters, [openFilter.value]: update });
  };

  const handleReset = () => {
    setIsOpen(false);

    if (typeof onReset === 'function') onReset();
  };

  const renderFilters = (child) => {
    if (isValidElement<FilterProps>(child)) {
      return cloneElement(child, {
        count: filters[child.props?.value]?.length,
        className:
          isOpen && openFilter?.value === child.props?.value ? 'active' : '',
        onClick: () => {
          if (isOpen && openFilter?.value === child.props?.value)
            setIsOpen(false);
          else {
            setOpenFilter(child.props);
            setIsOpen(true);
          }
        },
      });
    }
  };

  return (
    <Box ref={ref} bg="grey200">
      <LegacyContainer
        overflowX="auto"
        pt={0}
        pb={0}
        px={{ all: 2, md: 15 }}
        space={0}
        bg="grey200"
      >
        <Divider bg="grey300" />
        <Stack
          space={[1, 1, 1, 1.5]}
          paddingTop={3}
          paddingBottom={3}
          whiteSpace="nowrap"
        >
          {Children.map(children, renderFilters)}
        </Stack>
        <Divider bg="grey300" />
      </LegacyContainer>

      <Box
        width="100%"
        overflow="hidden"
        zIndex={10000}
        left={[0, 0, 'unset']}
        right={[0, 0, 'unset']}
        bottom={[0, 0, 'unset']}
        top={[0, 0, 'unset']}
        backgroundColor="grey200"
        transition="max-height ease 0.3s"
        position={['fixed', 'fixed', 'absolute']}
        display={[
          isOpen ? 'block' : 'none',
          isOpen ? 'block' : 'none',
          'block',
        ]}
        maxHeight={['none', 'none', isOpen ? `${height + 80}px` : 0]}
        boxShadow="0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);"
      >
        <Box
          pt={[4, 4, 5]}
          pb={[3, 3, 5]}
          height="100%"
          display="flex"
          ref={drawerRef}
          flexDirection="column"
          maxWidth="maxWidth"
          mx="auto"
          px={{ all: 3, md: 15 }}
        >
          <LegacyContainer
            display="flex"
            flexDirection="column"
            flex="1"
            pt={0}
            pb={0}
            px={0}
            space={3}
          >
            <Flex justifyContent="space-between">
              <Heading
                variant="h4"
                fontWeight="medium"
                color="#212C2A"
                mb={[0, 0, 2]}
                mt={[4, 4, 0]}
              >
                {openFilter?.title}
              </Heading>
              <Button
                variant="unstyled"
                onClick={() => setIsOpen(undefined)}
                display={['none', 'none', 'block']}
              >
                <Icon icon={ChevronUp} />
              </Button>
              <Button
                variant="unstyled"
                onClick={() => setIsOpen(undefined)}
                display={['block', 'block', 'none']}
                mb={['auto', 'auto', 0]}
              >
                <Icon icon={Close} />
              </Button>
            </Flex>
            <Stack
              mt={5}
              space={0}
              maxHeight="210px"
              flexDirection="column"
              flexWrap={['nowrap', 'nowrap', 'wrap']}
            >
              {openFilter?.options?.map((option, index) => (
                <Checkbox
                  mb={3}
                  key={index}
                  label={option.label}
                  onChange={() => handleChange(option)}
                  checked={!!filters[openFilter.value]?.includes(option.value)}
                />
              ))}
            </Stack>
            <Box
              mt="-4px"
              borderTopWidth={1}
              borderColor="grey300"
              borderTopStyle="solid"
              display={['none', 'none', 'block']}
            />

            <Flex
              flex={1}
              flexDirection={['column', 'column', 'row']}
              justifyContent={['flex-end', 'flex-end', 'center']}
            >
              <Button
                variant="secondary"
                onClick={() => setIsOpen(undefined)}
                mb={[0.25, 0.25, 0]}
              >
                {translations?.set_filters}
              </Button>
              <Button
                variant="link"
                ml={[0, 0, 3]}
                mt={[2, 2, 0]}
                hideArrow
                alignItems="center"
                justifyContent="center"
                fontSize={[1.5, 1.5, 1.75]}
                borderColor="transparent"
                onClick={handleReset}
              >
                {translations?.clear_filters}
              </Button>
            </Flex>
          </LegacyContainer>
        </Box>
      </Box>
    </Box>
  );
};

Filters.Filter = Filter;
