import { forwardRef } from 'react';
import { pick, omit } from '@styled-system/props';
import { useId } from 'react-id-generator';

import { InputWrapper, Text, Checkbox } from '~components';
import { RichText } from '../RichText';

export const TermsAndConditions = forwardRef(
  ({ content, error, disabled, ...props }: any, ref) => {
    const autoId = useId();
    const id = props.id || `input-${autoId}`;

    return (
      <InputWrapper error={error} disabled={disabled} {...pick(props)}>
        <Text as="label" htmlFor={id} textAlign="left" fontSize={2}>
          <Checkbox ref={ref} id={id} {...omit(props)} />

          <RichText text={content} />
        </Text>
      </InputWrapper>
    );
  },
);
