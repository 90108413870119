import { Flex } from '@storyofams/react-ui';
import { SystemProps, Box } from '@storyofams/react-ui';
import { NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer';
import { RichText as RichTextComponent } from '~components';
import { CookiebarItem } from '~lib/storyblok/sdk';
import { Button } from '../Button';

type RichTextProps = {
  text: any;
} & SystemProps;

const RichText = ({ text, ...props }: RichTextProps) => (
  <RichTextComponent
    text={text}
    nodeResolvers={{
      [NODE_PARAGRAPH]: (children) => (
        <Box pb={[2]} as="p" fontSize={[1.5, 1.5, 1.75]}>
          {children}
        </Box>
      ),
    }}
    {...props}
  />
);

interface CookieBarProps {
  showCookieBar?: boolean;
  acceptCookies(): void;
}

export const CookieBar = ({
  showCookieBar,
  acceptCookies,
  text,
  buttonLabel,
}: CookieBarProps & CookiebarItem['content']) => {
  return (
    <Flex
      position="fixed"
      left={0}
      right={0}
      bottom={showCookieBar ? 0 : '-100%'}
      transition="bottom .25s ease-in-out"
      flexDirection={['column', 'column', 'column', 'row']}
      width="100%"
      bg="white"
      boxShadow="0px -4px 10px rgba(0, 0, 0, 0.03)"
      alignItems="center"
      justifyContent="space-between"
      px={[2, 2, 3]}
      py={3}
      gap={3}
      zIndex="banner"
    >
      <RichText text={text} />

      <Button width={['100%', '100%', 'auto']} flexShrink={0} onClick={acceptCookies}>
        {buttonLabel}
      </Button>
    </Flex>
  );
};
