import { NextSeo, NextSeoProps } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";

import { getDescription } from "~lib";

interface SeoProps extends NextSeoProps {
  story?: any;
  image?: string;
  ogType?: "website" | "article";
  noIndex?: boolean;
}

export const Seo = ({
  story,
  title: titleProp,
  description: descriptionProp,
  image: imageProp,
  ogType,
  noIndex,
  ...props
}: SeoProps) => {
  const router = useRouter();
  const { defaultLocale, pathname, locale, locales } = router;
  
  let title = titleProp;
  let description = descriptionProp;
  let image = { url: imageProp };
  
  if (story?.content) {
    if (!title) {
      title = story?.content.seo?.title || story.title;
    }
    if (!description) {
      description = getDescription(story?.content);
    }
    if (!imageProp) {
      image = {
        url: story?.content?.seo?.og_image || story?.content?.image?.filename,
      };
    }
  }
  
  const standardTitle = "Philips MyCreation Professional Lighting Solutions";
  const isHome = locales.map((l) => `/${defaultLocale !== l ? `${l}/` : ""}`).includes(pathname);
  
  if (isHome) {
    title = standardTitle;
  } else {
    title = title
      ? `${title} - ${standardTitle}`
      : standardTitle;
  }
  
  return (
    <>
      <NextSeo
        title={title}
        description={description}
        noindex={story?.noIndex === true || noIndex}
        openGraph={{
          images: [image],
          type: ogType || "website",
          locale,
          site_name: standardTitle,
        }}
        {...props}
      />
      <Head>
        {locales?.filter((l) => l !== defaultLocale)?.map((content) => (
          <meta
            key={content}
            property="og:locale:alternate"
            content={content}
          />
        ))}
      </Head>
    </>
  );
};
