import React, { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useTranslations } from '~context';

import { Select, SelectProps } from '../Select';

const messages = defineMessages({
  eu: 'Europe and MET',
  anz: 'Australia - New Zealand',
  in: 'India',
  la: 'Latin America',
  na: 'North America',
});

type RegionSelectProps = SelectProps;

export const RegionSelect = ({ label, ...props }: RegionSelectProps) => {
  const { region, setRegion,  } = useTranslations();
  const { formatMessage } = useIntl();

  const regions = {
    eu: formatMessage(messages.eu),
    anz: formatMessage(messages.anz),
    // in: formatMessage(messages.in),
    la: formatMessage(messages.la),
    na: formatMessage(messages.na),
  }

  const options = useMemo(
    () =>
      Object.keys(regions)
        .map((key) => ({
          label: regions[key],
          value: key,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [regions],
  );


  return (
    <Select {...props} isSearchable={false} label={label} options={options} value={region} defaultValue={region} onChange={(val) => setRegion((val as any).value)} />
  );
};
