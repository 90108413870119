import React, { ReactNode, useRef } from 'react';
import { StoryProvider } from '@storyofams/storyblok-toolkit';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import { ThemeProvider } from 'styled-components';
import { NavProvider, TranslationsContext } from '~context';
import { TranslationsProvider } from '~context';

import theme from '~styles/theme';
import { QuotationProvider } from '~context/quotation';

type ProviderProps = {
  children: ReactNode;
  /** defaults to empty object */
  pageProps?: any;
  /** defaults to 'en' as per the defaultLocale */
  locale?: string;
};

export const Providers = ({
  children,
  locale = 'en',
  pageProps = {},
}: ProviderProps) => {
  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={pageProps.dehydratedState}>
        <ThemeProvider theme={theme}>
          <IntlProvider
            messages={pageProps.messages}
            defaultLocale="en"
            locale={locale?.split('-')[0]}
          >
            <StoryProvider
              token={process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}
              resolveRelations={[]}
            >
              <TranslationsProvider>
                <QuotationProvider>
                  <NavProvider>
                    {children}
                  </NavProvider>
                </QuotationProvider>
              </TranslationsProvider>
            </StoryProvider>
          </IntlProvider>
        </ThemeProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};

export const TestProviders = ({
  children,
  locale = 'en',
  pageProps = {},
}: ProviderProps) => {
  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        messages={pageProps.messages}
        defaultLocale="en"
        locale={locale}
      >
        <StoryProvider
          token={process.env.NEXT_PUBLIC_STORYBLOK_TOKEN}
          resolveRelations={[]}
        >
          <TranslationsContext.Provider value={{
            translations: {},
            region: 'eu',
            setRegion: () => {},
            language: 'en',
            setLanguage: () => {},
            hasLanguageSettings: true,
            setLanguageSelectorOpen: () => {},
            showSettingsModal: false,
            languageSelectorOpen: false,
            saveSettings: () => {},
            regionalLanguages: ['en']
          }}>
            <NavProvider>{children}</NavProvider>
          </TranslationsContext.Provider>
        </StoryProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};
