// import { loadMessages } from './loadMessages';
import { GetStaticPropsContext } from 'next';
import { ParsedUrlQuery } from 'querystring';
import storyblokSDK from './storyblok/client';

export const globalStaticProps =
  (getStaticProps) =>
  async (context: GetStaticPropsContext<ParsedUrlQuery>) => {
    const { locale, defaultLocale, locales, preview } = context;
    const [lang, ] = locale.split('-');
    const [
      { FooterItem: footer },
      { NavigationItem: navigation },
      { TranslationsItem: translations },
      { LangmodalItem: languageModal },
      { CookiebarItem: cookieBar },
      { ContactsectionItem: contactSection },
      { ShopmodalItem: shopModal },
      { CasepageItems: latestCases },
      staticProps,
      ...rest
    ] = await Promise.all([
      storyblokSDK(2).footer({
        slug: `${
          `${lang}/settings/`
        }footer`,
      }),
      storyblokSDK(2).navigation({
        slug: `${
          `${lang}/settings/`
        }navigation`,
      }),
      storyblokSDK(2).translations({
        slug: `${
          `${lang}/settings/`
        }translations`,
      }),
      storyblokSDK(2).languageModal({
        slug: `${
          `${lang}/settings/`
        }region-language-popup`,
      }),
      storyblokSDK(2).cookieBar({
        slug: `${
          `${lang}/settings/`
        }cookie-bar`,
      }),
      storyblokSDK(2).contactSection({
        slug: `${
          `${lang}/settings/`
        }contact-section`,
      }),
      storyblokSDK(2).shopModal(),
      storyblokSDK(2).casepageItems({
        perPage: 3,
      }),
      getStaticProps(context),
      // loadMessages(context),
    ]);

    return {
      ...staticProps,
      props: {
        ...(staticProps as any).props,
        footer,
        navigation,
        translations,
        languageModal,
        cookieBar,
        contactSection,
        shopModal,
        latestCases,
      },
    };
  };
