import { forwardRef } from 'react';
import { InputWrapperProps } from '@storyofams/react-ui';
import { pick, omit } from '@styled-system/props';
import dynamic from 'next/dynamic';
import { useId } from 'react-id-generator';
import type PhoneInputLib from 'react-phone-number-input';
import styled from 'styled-components';
import { InputWrapper } from '~components';

import 'react-phone-number-input/style.css';

const DynamicPhoneInput = dynamic(() => import('react-phone-number-input'), {
  ssr: false,
}) as typeof PhoneInputLib;

const Wrapper = styled.div<{ error?: any }>`
  .PhoneInputCountry {
    &:before {
      content: '';
    }
  }

  .PhoneInputCountry,
  .PhoneInputInput {
    appearance: none;
    text-decoration: none;

    display: inline-flex;

    min-height: 48px;

    font-size: ${(p) => p.theme.fontSizes[2]}px;

    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.grey300};
    border-radius: ${(p) => p.theme.radii.md};
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.16);

    transition: border-color 0.18s ease-in-out,
      background-color 0.18s ease-in-out;

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey500};
      text-overflow: ellipsis;
    }

    &:not(:disabled) {
      &:hover,
      &:active,
      &:focus {
        border-color: ${({ theme }) => theme.colors.black};
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  .PhoneInputCountry {
    margin-right: ${(p) => p.theme.space[1.5]}px;
    padding: ${(p) => p.theme.space[1]}px;
  }

  .PhoneInputCountryIcon--border {
    box-shadow: none;

    > img {
      border-radius: 2px;
    }
  }

  .PhoneInputCountrySelect
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    color: ${({ theme }) => theme.colors.grey500};
    border-color: ${({ theme }) => theme.colors.grey500};
  }

  .PhoneInputCountrySelect {
    &:hover,
    &:active,
    &:focus {
      & + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
        color: ${({ theme }) => theme.colors.grey900};
        border-color: ${({ theme }) => theme.colors.grey900};
      }
    }
  }

  .PhoneInputInput {
    text-decoration: none;
    padding: 11px ${(p) => p.theme.space[1.5]}px 10px;
    color: ${({ theme }) => theme.colors.grey900};

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey300};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;

      &:hover {
        border-color: ${({ theme }) => theme.colors.grey500};
      }
    }
  }
`;

export type PhoneInputProps = {
  defaultCountry?: string;
  id?: string;
  required?: boolean;
} & InputWrapperProps;

export const PhoneInput = forwardRef(
  (
    {
      status,
      statusMessage,
      label,
      error,
      id: givenId,
      ...rest
    }: PhoneInputProps,
    ref,
  ) => {
    const autoId = useId();
    const id = givenId || `select-${autoId}`;

    return (
      <Wrapper>
        <InputWrapper
          id={id}
          label={label}
          status={status}
          statusMessage={statusMessage}
          error={error}
          {...pick(rest)}
        >
          <DynamicPhoneInput ref={ref} id={id} {...omit(rest)} />
        </InputWrapper>
      </Wrapper>
    );
  },
);
