import { Box, system } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';
import styled from 'styled-components';

import {
  Button,
  FixedRatio,
  Heading,
  HoverArea,
  ScaleOnHover,
  Text,
} from '~components';

export const GalleryTitle = styled(Heading).attrs({
  as: 'h5',
  variant: 'h5',
  fontWeight: 'bold',
  color: 'secondary900',
  fontSize: [1.75, 1.75, 2.25],
})`
  ${system}
`;

export const GalleryItem = ({ title, image, ...props }) => {
  return (
    <HoverArea>
      <Box width="100%" {...props}>
        <FixedRatio ratio={[[282, 282, 150]]} width="100%" overflow="hidden">
          <ScaleOnHover>
            <Image
              alt={image?.alt}
              src={image?.filename}
              fluid={550}
              width="100%"
              height="100%"
            />
          </ScaleOnHover>
        </FixedRatio>

        <Button variant="secondary" mt={1.5} fontSize={1.75}>
          {title}
        </Button>
      </Box>
    </HoverArea>
  );
};

export const ImageWrapper = styled(Box)`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      180deg,
      rgba(9, 10, 10, 0) 0%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
`;

export const GalleryItemMobile = ({ title, image, link }) => {
  return (
    <Box position="relative" height="80px" width="100%">
      <ImageWrapper width="100%" height="100%">
        <Image
          height="100%"
          width="100%"
          src={image?.filename}
          alt={image?.alt}
          fluid={350}
        />
      </ImageWrapper>

      <Text
        variant="xs"
        position="absolute"
        bottom={2}
        left={2}
        fontWeight="bold"
        color="white"
        lineHeight="normal"
      >
        {title}
      </Text>
    </Box>
  );
};
