import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BlockScalar: any;
  ISO8601DateTime: any;
  JsonScalar: any;
};

export type Alternate = {
  __typename?: 'Alternate';
  fullSlug: Scalars['String'];
  id: Scalars['Int'];
  isFolder?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
  published: Scalars['Boolean'];
  slug: Scalars['String'];
};

export type ApplicationpageComponent = {
  __typename?: 'ApplicationpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  contactForm?: Maybe<Scalars['BlockScalar']>;
  hero?: Maybe<Scalars['BlockScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  summary?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Asset>;
  title?: Maybe<Scalars['String']>;
};

export type ApplicationpageFilterQuery = {
  summary?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type ApplicationpageItem = {
  __typename?: 'ApplicationpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ApplicationpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ApplicationpageItems = {
  __typename?: 'ApplicationpageItems';
  items?: Maybe<Array<Maybe<ApplicationpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Asset = {
  __typename?: 'Asset';
  alt?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  focus?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type CaseComponent = {
  __typename?: 'CaseComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Asset>;
  title?: Maybe<Scalars['String']>;
};

export type CaseFilterQuery = {
  summary?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type CaseItem = {
  __typename?: 'CaseItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CaseComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CaseItems = {
  __typename?: 'CaseItems';
  items?: Maybe<Array<Maybe<CaseItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CasepageComponent = {
  __typename?: 'CasepageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  case?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<Maybe<Story>>>;
  seo?: Maybe<Scalars['JsonScalar']>;
};


export type CasepageComponentFiltersArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
};

export type CasepageFilterQuery = {
  filters?: InputMaybe<FilterQueryOperations>;
};

export type CasepageItem = {
  __typename?: 'CasepageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CasepageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CasepageItems = {
  __typename?: 'CasepageItems';
  items?: Maybe<Array<Maybe<CasepageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CategoryComponent = {
  __typename?: 'CategoryComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  bold_title?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disable_sliders?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Asset>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type CategoryFilterQuery = {
  bold_title?: InputMaybe<FilterQueryOperations>;
  disable_sliders?: InputMaybe<FilterQueryOperations>;
  name?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
  variant?: InputMaybe<FilterQueryOperations>;
};

export type CategoryItem = {
  __typename?: 'CategoryItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CategoryComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CategoryItems = {
  __typename?: 'CategoryItems';
  items?: Maybe<Array<Maybe<CategoryItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ContactpageComponent = {
  __typename?: 'ContactpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['BlockScalar']>;
};

export type ContactpageItem = {
  __typename?: 'ContactpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ContactpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ContactpageItems = {
  __typename?: 'ContactpageItems';
  items?: Maybe<Array<Maybe<ContactpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ContactsectionComponent = {
  __typename?: 'ContactsectionComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body_image?: Maybe<Asset>;
  bold_title?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  cta_label?: Maybe<Scalars['String']>;
  cta_link?: Maybe<Link>;
  description?: Maybe<Scalars['String']>;
  download_label?: Maybe<Scalars['String']>;
  download_link?: Maybe<Link>;
  side_image?: Maybe<Asset>;
  title?: Maybe<Scalars['String']>;
};

export type ContactsectionFilterQuery = {
  bold_title?: InputMaybe<FilterQueryOperations>;
  cta_label?: InputMaybe<FilterQueryOperations>;
  description?: InputMaybe<FilterQueryOperations>;
  download_label?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type ContactsectionItem = {
  __typename?: 'ContactsectionItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ContactsectionComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ContactsectionItems = {
  __typename?: 'ContactsectionItems';
  items?: Maybe<Array<Maybe<ContactsectionItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ContentItem = {
  __typename?: 'ContentItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<Scalars['JsonScalar']>;
  content_string?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ContentItems = {
  __typename?: 'ContentItems';
  items?: Maybe<Array<Maybe<ContentItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CookiebarComponent = {
  __typename?: 'CookiebarComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  buttonLabel?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['JsonScalar']>;
};

export type CookiebarFilterQuery = {
  buttonLabel?: InputMaybe<FilterQueryOperations>;
};

export type CookiebarItem = {
  __typename?: 'CookiebarItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<CookiebarComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type CookiebarItems = {
  __typename?: 'CookiebarItems';
  items?: Maybe<Array<Maybe<CookiebarItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Datasource = {
  __typename?: 'Datasource';
  id: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type DatasourceEntries = {
  __typename?: 'DatasourceEntries';
  items: Array<DatasourceEntry>;
  total: Scalars['Int'];
};

export type DatasourceEntry = {
  __typename?: 'DatasourceEntry';
  dimensionValue?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Datasources = {
  __typename?: 'Datasources';
  items: Array<Datasource>;
};

export type DynamicmaterialpageComponent = {
  __typename?: 'DynamicmaterialpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  compatible_series?: Maybe<Array<Maybe<Story>>>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JsonScalar']>;
  image?: Maybe<Asset>;
  page?: Maybe<Scalars['BlockScalar']>;
  subtitle?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


export type DynamicmaterialpageComponentCompatible_SeriesArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
};

export type DynamicmaterialpageFilterQuery = {
  compatible_series?: InputMaybe<FilterQueryOperations>;
  subtitle?: InputMaybe<FilterQueryOperations>;
  summary?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type DynamicmaterialpageItem = {
  __typename?: 'DynamicmaterialpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<DynamicmaterialpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type DynamicmaterialpageItems = {
  __typename?: 'DynamicmaterialpageItems';
  items?: Maybe<Array<Maybe<DynamicmaterialpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type DynamicpageComponent = {
  __typename?: 'DynamicpageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  disable_breadcrumbs?: Maybe<Scalars['Boolean']>;
  inactive_regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  seo?: Maybe<Scalars['JsonScalar']>;
};

export type DynamicpageFilterQuery = {
  disable_breadcrumbs?: InputMaybe<FilterQueryOperations>;
  inactive_regions?: InputMaybe<FilterQueryOperations>;
};

export type DynamicpageItem = {
  __typename?: 'DynamicpageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<DynamicpageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type DynamicpageItems = {
  __typename?: 'DynamicpageItems';
  items?: Maybe<Array<Maybe<DynamicpageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FilterQueryOperations = {
  /** Must match all values of given array */
  all_in_array?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than date (Exmples: 2019-03-03 or 2020-03-03T03:03:03) */
  gt_date?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Greater than float value */
  gt_float?: InputMaybe<Scalars['Float']>;
  /** Greater than integer value */
  gt_int?: InputMaybe<Scalars['Int']>;
  /** Matches exactly one value */
  in?: InputMaybe<Scalars['String']>;
  /** Matches any value of given array */
  in_array?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Matches exactly one integer value */
  in_int?: InputMaybe<Scalars['Int']>;
  /** Matches exactly one value with a wildcard search using * */
  like?: InputMaybe<Scalars['String']>;
  /** Less than date (Format: 2019-03-03 or 2020-03-03T03:03:03) */
  lt_date?: InputMaybe<Scalars['ISO8601DateTime']>;
  /** Less than float value */
  lt_float?: InputMaybe<Scalars['Float']>;
  /** Less than integer value */
  lt_int?: InputMaybe<Scalars['Int']>;
  /** Matches all without the given value */
  not_in?: InputMaybe<Scalars['String']>;
  /** Matches all without the given value */
  not_like?: InputMaybe<Scalars['String']>;
};

export type FilteritemComponent = {
  __typename?: 'FilteritemComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Asset>;
  value?: Maybe<Scalars['String']>;
};

export type FilteritemFilterQuery = {
  key?: InputMaybe<FilterQueryOperations>;
  label?: InputMaybe<FilterQueryOperations>;
  value?: InputMaybe<FilterQueryOperations>;
};

export type FilteritemItem = {
  __typename?: 'FilteritemItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FilteritemComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FilteritemItems = {
  __typename?: 'FilteritemItems';
  items?: Maybe<Array<Maybe<FilteritemItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FiltersComponent = {
  __typename?: 'FiltersComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  application?: Maybe<Array<Maybe<Story>>>;
  component?: Maybe<Scalars['String']>;
  product_serie?: Maybe<Array<Maybe<Story>>>;
  region?: Maybe<Array<Maybe<Story>>>;
};


export type FiltersComponentApplicationArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
};


export type FiltersComponentProduct_SerieArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
};


export type FiltersComponentRegionArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
};

export type FiltersFilterQuery = {
  application?: InputMaybe<FilterQueryOperations>;
  product_serie?: InputMaybe<FilterQueryOperations>;
  region?: InputMaybe<FilterQueryOperations>;
};

export type FiltersItem = {
  __typename?: 'FiltersItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FiltersComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FiltersItems = {
  __typename?: 'FiltersItems';
  items?: Maybe<Array<Maybe<FiltersItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type FooterComponent = {
  __typename?: 'FooterComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  bottom_links?: Maybe<Scalars['BlockScalar']>;
  bottom_text?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  hideForPages?: Maybe<Array<Maybe<Story>>>;
  link_list?: Maybe<Scalars['BlockScalar']>;
  newsletterHubspotFormId?: Maybe<Scalars['String']>;
  newsletterHubspotPortalId?: Maybe<Scalars['String']>;
  newsletter_description?: Maybe<Scalars['String']>;
  newsletter_placeholder?: Maybe<Scalars['String']>;
  newsletter_title?: Maybe<Scalars['String']>;
  social_links?: Maybe<Scalars['BlockScalar']>;
  social_title?: Maybe<Scalars['String']>;
};


export type FooterComponentHideForPagesArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
};

export type FooterFilterQuery = {
  bottom_text?: InputMaybe<FilterQueryOperations>;
  hideForPages?: InputMaybe<FilterQueryOperations>;
  newsletterHubspotFormId?: InputMaybe<FilterQueryOperations>;
  newsletterHubspotPortalId?: InputMaybe<FilterQueryOperations>;
  newsletter_description?: InputMaybe<FilterQueryOperations>;
  newsletter_placeholder?: InputMaybe<FilterQueryOperations>;
  newsletter_title?: InputMaybe<FilterQueryOperations>;
  social_title?: InputMaybe<FilterQueryOperations>;
};

export type FooterItem = {
  __typename?: 'FooterItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<FooterComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type FooterItems = {
  __typename?: 'FooterItems';
  items?: Maybe<Array<Maybe<FooterItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type HomepageComponent = {
  __typename?: 'HomepageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  hero?: Maybe<Scalars['BlockScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
};

export type HomepageItem = {
  __typename?: 'HomepageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<HomepageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type HomepageItems = {
  __typename?: 'HomepageItems';
  items?: Maybe<Array<Maybe<HomepageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type LangmodalComponent = {
  __typename?: 'LangmodalComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  drawer_button?: Maybe<Scalars['String']>;
  drawer_title?: Maybe<Scalars['String']>;
  language_input?: Maybe<Scalars['String']>;
  modal_button?: Maybe<Scalars['String']>;
  modal_description?: Maybe<Scalars['String']>;
  modal_title?: Maybe<Scalars['String']>;
  region_input?: Maybe<Scalars['String']>;
};

export type LangmodalFilterQuery = {
  drawer_button?: InputMaybe<FilterQueryOperations>;
  drawer_title?: InputMaybe<FilterQueryOperations>;
  language_input?: InputMaybe<FilterQueryOperations>;
  modal_button?: InputMaybe<FilterQueryOperations>;
  modal_description?: InputMaybe<FilterQueryOperations>;
  modal_title?: InputMaybe<FilterQueryOperations>;
  region_input?: InputMaybe<FilterQueryOperations>;
};

export type LangmodalItem = {
  __typename?: 'LangmodalItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<LangmodalComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type LangmodalItems = {
  __typename?: 'LangmodalItems';
  items?: Maybe<Array<Maybe<LangmodalItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Link = {
  __typename?: 'Link';
  cachedUrl: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fieldtype: Scalars['String'];
  id: Scalars['String'];
  linktype: Scalars['String'];
  story?: Maybe<Story>;
  url: Scalars['String'];
};

export type LinkEntries = {
  __typename?: 'LinkEntries';
  items: Array<LinkEntry>;
};

export type LinkEntry = {
  __typename?: 'LinkEntry';
  id?: Maybe<Scalars['Int']>;
  isFolder?: Maybe<Scalars['Boolean']>;
  isStartpage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  published?: Maybe<Scalars['Boolean']>;
  slug?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type NavigationComponent = {
  __typename?: 'NavigationComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  hideForPages?: Maybe<Array<Maybe<Story>>>;
  menuItems?: Maybe<Scalars['BlockScalar']>;
  quotation_button?: Maybe<Scalars['String']>;
};


export type NavigationComponentHideForPagesArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
};

export type NavigationFilterQuery = {
  hideForPages?: InputMaybe<FilterQueryOperations>;
  quotation_button?: InputMaybe<FilterQueryOperations>;
};

export type NavigationItem = {
  __typename?: 'NavigationItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<NavigationComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type NavigationItems = {
  __typename?: 'NavigationItems';
  items?: Maybe<Array<Maybe<NavigationItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type OverviewComponent = {
  __typename?: 'OverviewComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  body_top?: Maybe<Scalars['BlockScalar']>;
  bold_title?: Maybe<Scalars['String']>;
  break_bold?: Maybe<Scalars['Boolean']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disable_sliders?: Maybe<Scalars['Boolean']>;
  discover?: Maybe<Scalars['String']>;
  image?: Maybe<Asset>;
  materials?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  textures?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
};

export type OverviewFilterQuery = {
  bold_title?: InputMaybe<FilterQueryOperations>;
  break_bold?: InputMaybe<FilterQueryOperations>;
  description?: InputMaybe<FilterQueryOperations>;
  disable_sliders?: InputMaybe<FilterQueryOperations>;
  discover?: InputMaybe<FilterQueryOperations>;
  materials?: InputMaybe<FilterQueryOperations>;
  textures?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
  variant?: InputMaybe<FilterQueryOperations>;
};

export type OverviewItem = {
  __typename?: 'OverviewItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<OverviewComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type OverviewItems = {
  __typename?: 'OverviewItems';
  items?: Maybe<Array<Maybe<OverviewItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type PageComponent = {
  __typename?: 'PageComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['BlockScalar']>;
  component?: Maybe<Scalars['String']>;
  contactForm?: Maybe<Scalars['BlockScalar']>;
  enable_filters?: Maybe<Scalars['Boolean']>;
  hero?: Maybe<Scalars['BlockScalar']>;
  seo?: Maybe<Scalars['JsonScalar']>;
};

export type PageFilterQuery = {
  enable_filters?: InputMaybe<FilterQueryOperations>;
};

export type PageItem = {
  __typename?: 'PageItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<PageComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type PageItems = {
  __typename?: 'PageItems';
  items?: Maybe<Array<Maybe<PageItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductdetailComponent = {
  __typename?: 'ProductdetailComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
};

export type ProductdetailItem = {
  __typename?: 'ProductdetailItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductdetailComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductdetailItems = {
  __typename?: 'ProductdetailItems';
  items?: Maybe<Array<Maybe<ProductdetailItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductserieComponent = {
  __typename?: 'ProductserieComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
};

export type ProductserieItem = {
  __typename?: 'ProductserieItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductserieComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductserieItems = {
  __typename?: 'ProductserieItems';
  items?: Maybe<Array<Maybe<ProductserieItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductseriesComponent = {
  __typename?: 'ProductseriesComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  awards?: Maybe<Asset>;
  body?: Maybe<Scalars['BlockScalar']>;
  body_top?: Maybe<Scalars['BlockScalar']>;
  category?: Maybe<Story>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['JsonScalar']>;
  downloads?: Maybe<Scalars['BlockScalar']>;
  hide_configurator_and_downloads?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Asset>;
  introduction_image_on_right?: Maybe<Scalars['Boolean']>;
  links?: Maybe<Scalars['BlockScalar']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  seo?: Maybe<Scalars['JsonScalar']>;
  summary?: Maybe<Scalars['JsonScalar']>;
  usps?: Maybe<Scalars['JsonScalar']>;
};


export type ProductseriesComponentCategoryArgs = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  language?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};

export type ProductseriesFilterQuery = {
  category?: InputMaybe<FilterQueryOperations>;
  hide_configurator_and_downloads?: InputMaybe<FilterQueryOperations>;
  introduction_image_on_right?: InputMaybe<FilterQueryOperations>;
  name?: InputMaybe<FilterQueryOperations>;
  product?: InputMaybe<FilterQueryOperations>;
};

export type ProductseriesItem = {
  __typename?: 'ProductseriesItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductseriesComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductseriesItems = {
  __typename?: 'ProductseriesItems';
  items?: Maybe<Array<Maybe<ProductseriesItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ProductsoverviewComponent = {
  __typename?: 'ProductsoverviewComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  hero?: Maybe<Scalars['String']>;
};

export type ProductsoverviewFilterQuery = {
  header?: InputMaybe<FilterQueryOperations>;
  hero?: InputMaybe<FilterQueryOperations>;
};

export type ProductsoverviewItem = {
  __typename?: 'ProductsoverviewItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ProductsoverviewComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ProductsoverviewItems = {
  __typename?: 'ProductsoverviewItems';
  items?: Maybe<Array<Maybe<ProductsoverviewItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type QueryType = {
  __typename?: 'QueryType';
  ApplicationpageItem?: Maybe<ApplicationpageItem>;
  ApplicationpageItems?: Maybe<ApplicationpageItems>;
  CaseItem?: Maybe<CaseItem>;
  CaseItems?: Maybe<CaseItems>;
  CasepageItem?: Maybe<CasepageItem>;
  CasepageItems?: Maybe<CasepageItems>;
  CategoryItem?: Maybe<CategoryItem>;
  CategoryItems?: Maybe<CategoryItems>;
  ContactpageItem?: Maybe<ContactpageItem>;
  ContactpageItems?: Maybe<ContactpageItems>;
  ContactsectionItem?: Maybe<ContactsectionItem>;
  ContactsectionItems?: Maybe<ContactsectionItems>;
  ContentNode?: Maybe<ContentItem>;
  ContentNodes?: Maybe<ContentItems>;
  CookiebarItem?: Maybe<CookiebarItem>;
  CookiebarItems?: Maybe<CookiebarItems>;
  DatasourceEntries?: Maybe<DatasourceEntries>;
  Datasources?: Maybe<Datasources>;
  DynamicmaterialpageItem?: Maybe<DynamicmaterialpageItem>;
  DynamicmaterialpageItems?: Maybe<DynamicmaterialpageItems>;
  DynamicpageItem?: Maybe<DynamicpageItem>;
  DynamicpageItems?: Maybe<DynamicpageItems>;
  FilteritemItem?: Maybe<FilteritemItem>;
  FilteritemItems?: Maybe<FilteritemItems>;
  FiltersItem?: Maybe<FiltersItem>;
  FiltersItems?: Maybe<FiltersItems>;
  FooterItem?: Maybe<FooterItem>;
  FooterItems?: Maybe<FooterItems>;
  HomepageItem?: Maybe<HomepageItem>;
  HomepageItems?: Maybe<HomepageItems>;
  LangmodalItem?: Maybe<LangmodalItem>;
  LangmodalItems?: Maybe<LangmodalItems>;
  Links?: Maybe<LinkEntries>;
  NavigationItem?: Maybe<NavigationItem>;
  NavigationItems?: Maybe<NavigationItems>;
  OverviewItem?: Maybe<OverviewItem>;
  OverviewItems?: Maybe<OverviewItems>;
  PageItem?: Maybe<PageItem>;
  PageItems?: Maybe<PageItems>;
  ProductdetailItem?: Maybe<ProductdetailItem>;
  ProductdetailItems?: Maybe<ProductdetailItems>;
  ProductserieItem?: Maybe<ProductserieItem>;
  ProductserieItems?: Maybe<ProductserieItems>;
  ProductseriesItem?: Maybe<ProductseriesItem>;
  ProductseriesItems?: Maybe<ProductseriesItems>;
  ProductsoverviewItem?: Maybe<ProductsoverviewItem>;
  ProductsoverviewItems?: Maybe<ProductsoverviewItems>;
  RateLimit?: Maybe<RateLimit>;
  ShopmodalItem?: Maybe<ShopmodalItem>;
  ShopmodalItems?: Maybe<ShopmodalItems>;
  Space?: Maybe<Space>;
  Tags?: Maybe<Tags>;
  TempItem?: Maybe<TempItem>;
  TempItems?: Maybe<TempItems>;
  TranslationsItem?: Maybe<TranslationsItem>;
  TranslationsItems?: Maybe<TranslationsItems>;
};


export type QueryTypeApplicationpageItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeApplicationpageItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<ApplicationpageFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCaseItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCaseItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<CaseFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCasepageItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCasepageItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<CasepageFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCategoryItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCategoryItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<CategoryFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeContactpageItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeContactpageItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeContactsectionItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeContactsectionItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<ContactsectionFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeContentNodeArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeContentNodesArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCookiebarItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeCookiebarItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<CookiebarFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeDatasourceEntriesArgs = {
  datasource?: InputMaybe<Scalars['String']>;
  dimension?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
};


export type QueryTypeDatasourcesArgs = {
  by_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryTypeDynamicmaterialpageItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeDynamicmaterialpageItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<DynamicmaterialpageFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeDynamicpageItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeDynamicpageItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<DynamicpageFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFilteritemItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFilteritemItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<FilteritemFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFiltersItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFiltersItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<FiltersFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFooterItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeFooterItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<FooterFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeHomepageItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeHomepageItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeLangmodalItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeLangmodalItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<LangmodalFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeLinksArgs = {
  paginated?: InputMaybe<Scalars['Boolean']>;
  starts_with?: InputMaybe<Scalars['String']>;
};


export type QueryTypeNavigationItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeNavigationItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<NavigationFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeOverviewItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeOverviewItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<OverviewFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypePageItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypePageItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<PageFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductdetailItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductdetailItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductserieItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductserieItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductseriesItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductseriesItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<ProductseriesFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductsoverviewItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeProductsoverviewItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<ProductsoverviewFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeShopmodalItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeShopmodalItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<ShopmodalFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeTagsArgs = {
  starts_with?: InputMaybe<Scalars['String']>;
};


export type QueryTypeTempItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeTempItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};


export type QueryTypeTranslationsItemArgs = {
  find_by?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
};


export type QueryTypeTranslationsItemsArgs = {
  by_slugs?: InputMaybe<Scalars['String']>;
  by_uuids?: InputMaybe<Scalars['String']>;
  by_uuids_ordered?: InputMaybe<Scalars['String']>;
  excluding_fields?: InputMaybe<Scalars['String']>;
  excluding_ids?: InputMaybe<Scalars['String']>;
  excluding_slugs?: InputMaybe<Scalars['String']>;
  fallback_lang?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
  filter_query_v2?: InputMaybe<TranslationsFilterQuery>;
  first_published_at_gt?: InputMaybe<Scalars['String']>;
  first_published_at_lt?: InputMaybe<Scalars['String']>;
  from_release?: InputMaybe<Scalars['String']>;
  is_startpage?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  per_page?: InputMaybe<Scalars['Int']>;
  published_at_gt?: InputMaybe<Scalars['String']>;
  published_at_lt?: InputMaybe<Scalars['String']>;
  resolve_links?: InputMaybe<Scalars['String']>;
  resolve_relations?: InputMaybe<Scalars['String']>;
  search_term?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  starts_with?: InputMaybe<Scalars['String']>;
  with_tag?: InputMaybe<Scalars['String']>;
};

export type RateLimit = {
  __typename?: 'RateLimit';
  maxCost: Scalars['Int'];
};

export type ShopmodalComponent = {
  __typename?: 'ShopmodalComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  button?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ShopmodalFilterQuery = {
  button?: InputMaybe<FilterQueryOperations>;
  description?: InputMaybe<FilterQueryOperations>;
  title?: InputMaybe<FilterQueryOperations>;
};

export type ShopmodalItem = {
  __typename?: 'ShopmodalItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<ShopmodalComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type ShopmodalItems = {
  __typename?: 'ShopmodalItems';
  items?: Maybe<Array<Maybe<ShopmodalItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type Space = {
  __typename?: 'Space';
  domain: Scalars['String'];
  id: Scalars['Int'];
  languageCodes: Array<Maybe<Scalars['String']>>;
  name: Scalars['String'];
  version: Scalars['Int'];
};

export type Story = {
  __typename?: 'Story';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<Scalars['JsonScalar']>;
  createdAt?: Maybe<Scalars['String']>;
  firstPublishedAt?: Maybe<Scalars['String']>;
  fullSlug?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  isStartpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  metaData?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  publishedAt?: Maybe<Scalars['String']>;
  releaseId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sortByDate?: Maybe<Scalars['String']>;
  tagList?: Maybe<Array<Maybe<Scalars['String']>>>;
  translatedSlugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type Tag = {
  __typename?: 'Tag';
  name: Scalars['String'];
  taggingsCount: Scalars['Int'];
};

export type Tags = {
  __typename?: 'Tags';
  items: Array<Tag>;
};

export type TempComponent = {
  __typename?: 'TempComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
};

export type TempItem = {
  __typename?: 'TempItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<TempComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type TempItems = {
  __typename?: 'TempItems';
  items?: Maybe<Array<Maybe<TempItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type TranslatedSlug = {
  __typename?: 'TranslatedSlug';
  lang: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type TranslationsComponent = {
  __typename?: 'TranslationsComponent';
  _editable?: Maybe<Scalars['String']>;
  _uid?: Maybe<Scalars['String']>;
  accept_message?: Maybe<Scalars['String']>;
  applications?: Maybe<Scalars['String']>;
  back?: Maybe<Scalars['String']>;
  case_studies?: Maybe<Scalars['String']>;
  cases?: Maybe<Scalars['String']>;
  clear_filters?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  compatible_series?: Maybe<Scalars['String']>;
  component?: Maybe<Scalars['String']>;
  configure_this_model?: Maybe<Scalars['String']>;
  configure_your_product?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  discover?: Maybe<Scalars['String']>;
  discover_all?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  here?: Maybe<Scalars['String']>;
  job_title?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  less_details?: Maybe<Scalars['String']>;
  load_more_projects?: Maybe<Scalars['String']>;
  materials_all?: Maybe<Scalars['String']>;
  more_details?: Maybe<Scalars['String']>;
  no_results?: Maybe<Scalars['String']>;
  not_found?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  products?: Maybe<Scalars['String']>;
  read_more?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  save_settings?: Maybe<Scalars['String']>;
  server_error?: Maybe<Scalars['String']>;
  set_filters?: Maybe<Scalars['String']>;
  show_product_downloads?: Maybe<Scalars['String']>;
  start_configurator?: Maybe<Scalars['String']>;
  unauthorized?: Maybe<Scalars['String']>;
  unknown?: Maybe<Scalars['String']>;
};

export type TranslationsFilterQuery = {
  accept_message?: InputMaybe<FilterQueryOperations>;
  applications?: InputMaybe<FilterQueryOperations>;
  back?: InputMaybe<FilterQueryOperations>;
  case_studies?: InputMaybe<FilterQueryOperations>;
  cases?: InputMaybe<FilterQueryOperations>;
  clear_filters?: InputMaybe<FilterQueryOperations>;
  company?: InputMaybe<FilterQueryOperations>;
  compatible_series?: InputMaybe<FilterQueryOperations>;
  configure_this_model?: InputMaybe<FilterQueryOperations>;
  configure_your_product?: InputMaybe<FilterQueryOperations>;
  country?: InputMaybe<FilterQueryOperations>;
  discover?: InputMaybe<FilterQueryOperations>;
  discover_all?: InputMaybe<FilterQueryOperations>;
  email?: InputMaybe<FilterQueryOperations>;
  first_name?: InputMaybe<FilterQueryOperations>;
  here?: InputMaybe<FilterQueryOperations>;
  job_title?: InputMaybe<FilterQueryOperations>;
  language?: InputMaybe<FilterQueryOperations>;
  last_name?: InputMaybe<FilterQueryOperations>;
  less_details?: InputMaybe<FilterQueryOperations>;
  load_more_projects?: InputMaybe<FilterQueryOperations>;
  materials_all?: InputMaybe<FilterQueryOperations>;
  more_details?: InputMaybe<FilterQueryOperations>;
  no_results?: InputMaybe<FilterQueryOperations>;
  not_found?: InputMaybe<FilterQueryOperations>;
  phone_number?: InputMaybe<FilterQueryOperations>;
  postal_code?: InputMaybe<FilterQueryOperations>;
  products?: InputMaybe<FilterQueryOperations>;
  read_more?: InputMaybe<FilterQueryOperations>;
  region?: InputMaybe<FilterQueryOperations>;
  save_settings?: InputMaybe<FilterQueryOperations>;
  server_error?: InputMaybe<FilterQueryOperations>;
  set_filters?: InputMaybe<FilterQueryOperations>;
  show_product_downloads?: InputMaybe<FilterQueryOperations>;
  start_configurator?: InputMaybe<FilterQueryOperations>;
  unauthorized?: InputMaybe<FilterQueryOperations>;
  unknown?: InputMaybe<FilterQueryOperations>;
};

export type TranslationsItem = {
  __typename?: 'TranslationsItem';
  alternates?: Maybe<Array<Maybe<Alternate>>>;
  content?: Maybe<TranslationsComponent>;
  created_at?: Maybe<Scalars['String']>;
  default_full_slug?: Maybe<Scalars['String']>;
  first_published_at?: Maybe<Scalars['String']>;
  full_slug?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_startpage?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  meta_data?: Maybe<Scalars['JsonScalar']>;
  name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  sort_by_date?: Maybe<Scalars['String']>;
  tag_list?: Maybe<Array<Maybe<Scalars['String']>>>;
  translated_slugs?: Maybe<Array<Maybe<TranslatedSlug>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type TranslationsItems = {
  __typename?: 'TranslationsItems';
  items?: Maybe<Array<Maybe<TranslationsItem>>>;
  total?: Maybe<Scalars['Int']>;
};

export type ApplicationsPageQueryVariables = Exact<{
  slug: Scalars['ID'];
  language?: InputMaybe<Scalars['String']>;
}>;


export type ApplicationsPageQuery = { __typename?: 'QueryType', ApplicationpageItem?: { __typename?: 'ApplicationpageItem', id?: number | null, uuid?: string | null, slug?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'ApplicationpageComponent', _uid?: string | null, _editable?: string | null, body?: any | null, contactForm?: any | null, hero?: any | null, seo?: any | null, summary?: string | null, title?: string | null, thumbnail?: { __typename?: 'Asset', alt?: string | null, filename: string, focus?: string | null } | null } | null } | null };

export type CasepageItemQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type CasepageItemQuery = { __typename?: 'QueryType', CasepageItem?: { __typename?: 'CasepageItem', uuid?: string | null, name?: string | null, slug?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'CasepageComponent', _editable?: string | null, _uid?: string | null, case?: any | null, body?: any | null, seo?: any | null } | null } | null };

export type CasepageItemsQueryVariables = Exact<{
  starts_with?: InputMaybe<Scalars['String']>;
  perPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
}>;


export type CasepageItemsQuery = { __typename?: 'QueryType', CasepageItems?: { __typename?: 'CasepageItems', total?: number | null, items?: Array<{ __typename?: 'CasepageItem', uuid?: string | null, slug?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'CasepageComponent', case?: any | null, body?: any | null } | null } | null> | null } | null };

export type CategoriesQueryVariables = Exact<{
  starts_with: Scalars['String'];
}>;


export type CategoriesQuery = { __typename?: 'QueryType', CategoryItems?: { __typename?: 'CategoryItems', items?: Array<{ __typename?: 'CategoryItem', uuid?: string | null, name?: string | null, slug?: string | null, content?: { __typename?: 'CategoryComponent', name?: string | null } | null } | null> | null } | null };

export type CategoryItemQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type CategoryItemQuery = { __typename?: 'QueryType', CategoryItem?: { __typename?: 'CategoryItem', uuid?: string | null, name?: string | null, slug?: string | null, content?: { __typename?: 'CategoryComponent', name?: string | null, body?: any | null, disable_sliders?: boolean | null, _uid?: string | null, _editable?: string | null } | null } | null };

export type ContactSectionQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type ContactSectionQuery = { __typename?: 'QueryType', ContactsectionItem?: { __typename?: 'ContactsectionItem', published_at?: string | null, uuid?: string | null, content?: { __typename?: 'ContactsectionComponent', _editable?: string | null, _uid?: string | null, title?: string | null, bold_title?: string | null, description?: string | null, download_label?: string | null, cta_label?: string | null, body_image?: { __typename?: 'Asset', alt?: string | null, filename: string, focus?: string | null } | null, side_image?: { __typename?: 'Asset', alt?: string | null, filename: string, focus?: string | null } | null, download_link?: { __typename?: 'Link', cachedUrl: string, email?: string | null, fieldtype: string, id: string, linktype: string, url: string, story?: { __typename?: 'Story', name?: string | null, slug?: string | null } | null } | null, cta_link?: { __typename?: 'Link', cachedUrl: string, email?: string | null, fieldtype: string, id: string, linktype: string, url: string, story?: { __typename?: 'Story', name?: string | null, slug?: string | null } | null } | null } | null } | null };

export type CookieBarQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type CookieBarQuery = { __typename?: 'QueryType', CookiebarItem?: { __typename?: 'CookiebarItem', published_at?: string | null, uuid?: string | null, content?: { __typename?: 'CookiebarComponent', text?: any | null, buttonLabel?: string | null } | null } | null };

export type DynamicMaterialPageByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DynamicMaterialPageByIdQuery = { __typename?: 'QueryType', DynamicmaterialpageItem?: { __typename?: 'DynamicmaterialpageItem', id?: number | null, uuid?: string | null, name?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'DynamicmaterialpageComponent', _editable?: string | null, _uid?: string | null, title?: string | null, subtitle?: string | null, page?: any | null, description?: any | null, component?: string | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null, compatible_series?: Array<{ __typename?: 'Story', content?: any | null, uuid?: string | null } | null> | null } | null } | null };

export type DynamicPageByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DynamicPageByIdQuery = { __typename?: 'QueryType', DynamicpageItem?: { __typename?: 'DynamicpageItem', id?: number | null, uuid?: string | null, name?: string | null, slug?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'DynamicpageComponent', body?: any | null, component?: string | null, disable_breadcrumbs?: boolean | null, seo?: any | null, inactive_regions?: Array<string | null> | null, _uid?: string | null, _editable?: string | null } | null } | null };

export type DynamicPagesQueryVariables = Exact<{ [key: string]: never; }>;


export type DynamicPagesQuery = { __typename?: 'QueryType', DynamicpageItems?: { __typename?: 'DynamicpageItems', items?: Array<{ __typename?: 'DynamicpageItem', id?: number | null, uuid?: string | null, name?: string | null, slug?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'DynamicpageComponent', body?: any | null, component?: string | null, _uid?: string | null, _editable?: string | null, seo?: any | null } | null } | null> | null } | null };

export type FiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type FiltersQuery = { __typename?: 'QueryType', FilteritemItems?: { __typename?: 'FilteritemItems', items?: Array<{ __typename?: 'FilteritemItem', uuid?: string | null, content?: { __typename?: 'FilteritemComponent', label?: string | null, value?: string | null, key?: string | null } | null } | null> | null } | null };

export type FooterQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type FooterQuery = { __typename?: 'QueryType', FooterItem?: { __typename?: 'FooterItem', published_at?: string | null, uuid?: string | null, content?: { __typename?: 'FooterComponent', newsletter_title?: string | null, newsletter_description?: string | null, newsletter_placeholder?: string | null, newsletterHubspotPortalId?: string | null, newsletterHubspotFormId?: string | null, link_list?: any | null, social_title?: string | null, social_links?: any | null, bottom_links?: any | null, bottom_text?: string | null, hideForPages?: Array<{ __typename?: 'Story', slug?: string | null } | null> | null } | null } | null };

export type HomeQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type HomeQuery = { __typename?: 'QueryType', HomepageItem?: { __typename?: 'HomepageItem', uuid?: string | null, id?: number | null, name?: string | null, slug?: string | null, content?: { __typename?: 'HomepageComponent', hero?: any | null, body?: any | null, component?: string | null, _uid?: string | null, _editable?: string | null } | null } | null };

export type LanguageModalQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type LanguageModalQuery = { __typename?: 'QueryType', LangmodalItem?: { __typename?: 'LangmodalItem', published_at?: string | null, uuid?: string | null, content?: { __typename?: 'LangmodalComponent', modal_title?: string | null, modal_description?: string | null, modal_button?: string | null, drawer_title?: string | null, drawer_button?: string | null, region_input?: string | null, language_input?: string | null } | null } | null };

export type MaterialsQueryVariables = Exact<{ [key: string]: never; }>;


export type MaterialsQuery = { __typename?: 'QueryType', DynamicmaterialpageItems?: { __typename?: 'DynamicmaterialpageItems', items?: Array<{ __typename?: 'DynamicmaterialpageItem', id?: number | null, uuid?: string | null, name?: string | null, slug?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'DynamicmaterialpageComponent', _editable?: string | null, _uid?: string | null, title?: string | null, subtitle?: string | null, summary?: string | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null } | null } | null> | null } | null };

export type NavigationQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type NavigationQuery = { __typename?: 'QueryType', NavigationItem?: { __typename?: 'NavigationItem', published_at?: string | null, uuid?: string | null, content?: { __typename?: 'NavigationComponent', menuItems?: any | null, quotation_button?: string | null, hideForPages?: Array<{ __typename?: 'Story', slug?: string | null } | null> | null } | null } | null };

export type PageByIdQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type PageByIdQuery = { __typename?: 'QueryType', PageItem?: { __typename?: 'PageItem', id?: number | null, uuid?: string | null, name?: string | null, slug?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'PageComponent', body?: any | null, component?: string | null, enable_filters?: boolean | null, _uid?: string | null, _editable?: string | null, seo?: any | null } | null } | null };

export type ProductSerieQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type ProductSerieQuery = { __typename?: 'QueryType', ProductseriesItem?: { __typename?: 'ProductseriesItem', id?: number | null, uuid?: string | null, name?: string | null, slug?: string | null, content?: { __typename?: 'ProductseriesComponent', name?: string | null, description?: any | null, hide_configurator_and_downloads?: boolean | null, usps?: any | null, introduction_image_on_right?: boolean | null, links?: any | null, body?: any | null, body_top?: any | null, downloads?: any | null, component?: string | null, _uid?: string | null, _editable?: string | null, seo?: any | null, product?: string | null, category?: { __typename?: 'Story', slug?: string | null } | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string, focus?: string | null } | null, awards?: { __typename?: 'Asset', alt?: string | null, filename: string, focus?: string | null } | null } | null } | null };

export type ProductSeriesQueryVariables = Exact<{
  starts_with?: InputMaybe<Scalars['String']>;
  filter_query?: InputMaybe<Scalars['JsonScalar']>;
}>;


export type ProductSeriesQuery = { __typename?: 'QueryType', ProductseriesItems?: { __typename?: 'ProductseriesItems', items?: Array<{ __typename?: 'ProductseriesItem', id?: number | null, uuid?: string | null, name?: string | null, slug?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'ProductseriesComponent', _uid?: string | null, _editable?: string | null, name?: string | null, description?: any | null, hide_configurator_and_downloads?: boolean | null, summary?: any | null, category?: { __typename?: 'Story', name?: string | null, slug?: string | null, uuid?: string | null } | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string, focus?: string | null } | null } | null } | null> | null } | null };

export type ProductSeriesOverviewQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type ProductSeriesOverviewQuery = { __typename?: 'QueryType', OverviewItem?: { __typename?: 'OverviewItem', id?: number | null, uuid?: string | null, name?: string | null, slug?: string | null, content?: { __typename?: 'OverviewComponent', title?: string | null, bold_title?: string | null, break_bold?: boolean | null, variant?: string | null, description?: string | null, body?: any | null, body_top?: any | null, disable_sliders?: boolean | null, discover?: string | null, materials?: string | null, textures?: string | null, component?: string | null, _uid?: string | null, _editable?: string | null, seo?: any | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string, focus?: string | null } | null } | null } | null };

export type ShopModalQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['ID']>;
}>;


export type ShopModalQuery = { __typename?: 'QueryType', ShopmodalItem?: { __typename?: 'ShopmodalItem', content?: { __typename?: 'ShopmodalComponent', title?: string | null, description?: string | null, button?: string | null } | null } | null };

export type TexturesQueryVariables = Exact<{ [key: string]: never; }>;


export type TexturesQuery = { __typename?: 'QueryType', DynamicmaterialpageItems?: { __typename?: 'DynamicmaterialpageItems', items?: Array<{ __typename?: 'DynamicmaterialpageItem', id?: number | null, uuid?: string | null, name?: string | null, full_slug?: string | null, translated_slugs?: Array<{ __typename?: 'TranslatedSlug', lang: string, name?: string | null, path?: string | null } | null> | null, content?: { __typename?: 'DynamicmaterialpageComponent', _editable?: string | null, _uid?: string | null, title?: string | null, summary?: string | null, image?: { __typename?: 'Asset', alt?: string | null, filename: string } | null } | null } | null> | null } | null };

export type TranslationsQueryVariables = Exact<{
  slug: Scalars['ID'];
}>;


export type TranslationsQuery = { __typename?: 'QueryType', TranslationsItem?: { __typename?: 'TranslationsItem', uuid?: string | null, content?: { __typename?: 'TranslationsComponent', language?: string | null, region?: string | null, applications?: string | null, cases?: string | null, products?: string | null, read_more?: string | null, save_settings?: string | null, start_configurator?: string | null, show_product_downloads?: string | null, discover?: string | null, less_details?: string | null, more_details?: string | null, configure_this_model?: string | null, configure_your_product?: string | null, here?: string | null, discover_all?: string | null, no_results?: string | null, load_more_projects?: string | null, case_studies?: string | null, clear_filters?: string | null, compatible_series?: string | null, set_filters?: string | null, unauthorized?: string | null, unknown?: string | null, not_found?: string | null, back?: string | null, server_error?: string | null } | null } | null };


export const ApplicationsPageDocument = gql`
    query applicationsPage($slug: ID!, $language: String) {
  ApplicationpageItem(
    id: $slug
    language: $language
    resolve_relations: "cases.featured,cases.cases,applications.items"
  ) {
    id
    uuid
    slug
    full_slug
    translated_slugs {
      lang
      name
      path
    }
    content {
      _uid
      _editable
      body
      contactForm
      hero
      seo
      summary
      title
      thumbnail {
        alt
        filename
        focus
      }
    }
  }
}
    `;
export const CasepageItemDocument = gql`
    query casepageItem($slug: ID!) {
  CasepageItem(
    id: $slug
    resolve_links: "url"
    resolve_relations: "cases.featured,cases.cases,applications.items,product_series.categories"
  ) {
    uuid
    name
    slug
    full_slug
    translated_slugs {
      lang
      name
      path
    }
    content {
      _editable
      _uid
      case
      body
      seo
    }
  }
}
    `;
export const CasepageItemsDocument = gql`
    query casepageItems($starts_with: String, $perPage: Int, $page: Int, $filter_query: JsonScalar) {
  CasepageItems(
    starts_with: $starts_with
    per_page: $perPage
    page: $page
    filter_query: $filter_query
    resolve_links: "url"
    resolve_relations: "cases.featured,cases.cases,applications.items,product_series.categories"
  ) {
    items {
      uuid
      slug
      full_slug
      translated_slugs {
        lang
        name
        path
      }
      content {
        case
        body
      }
      full_slug
    }
    total
  }
}
    `;
export const CategoriesDocument = gql`
    query categories($starts_with: String!) {
  CategoryItems(starts_with: $starts_with) {
    items {
      uuid
      name
      slug
      content {
        name
      }
    }
  }
}
    `;
export const CategoryItemDocument = gql`
    query categoryItem($slug: ID!) {
  CategoryItem(
    id: $slug
    resolve_links: "url"
    resolve_relations: "cases.featured,cases.cases,applications.items,product_series.categories,scroll_bar.categories,scroll_bar.materials,scroll_bar.products"
  ) {
    uuid
    name
    slug
    content {
      name
      body
      disable_sliders
      _uid
      _editable
    }
  }
}
    `;
export const ContactSectionDocument = gql`
    query contactSection($slug: ID!) {
  ContactsectionItem(id: $slug, resolve_links: "url") {
    published_at
    uuid
    content {
      _editable
      _uid
      title
      bold_title
      body_image {
        alt
        filename
        focus
      }
      side_image {
        alt
        filename
        focus
      }
      description
      download_label
      download_link {
        cachedUrl
        email
        fieldtype
        id
        linktype
        story {
          name
          slug
        }
        url
      }
      cta_label
      cta_link {
        cachedUrl
        email
        fieldtype
        id
        linktype
        story {
          name
          slug
        }
        url
      }
    }
  }
}
    `;
export const CookieBarDocument = gql`
    query cookieBar($slug: ID!) {
  CookiebarItem(id: $slug, resolve_links: "url") {
    published_at
    uuid
    content {
      text
      buttonLabel
    }
  }
}
    `;
export const DynamicMaterialPageByIdDocument = gql`
    query dynamicMaterialPageById($id: ID!) {
  DynamicmaterialpageItem(
    id: $id
    resolve_links: "url"
    resolve_relations: "cases.featured,cases.cases,products.products,materials.items,product_series.categories,scroll_bar.categories,scroll_bar.materials,scroll_bar.products,materials.items"
  ) {
    id
    uuid
    name
    full_slug
    translated_slugs {
      lang
      name
      path
    }
    content {
      _editable
      _uid
      title
      subtitle
      page
      image {
        alt
        filename
      }
      description
      component
      compatible_series {
        content
        uuid
      }
    }
  }
}
    `;
export const DynamicPageByIdDocument = gql`
    query dynamicPageById($id: ID!) {
  DynamicpageItem(
    id: $id
    resolve_links: "url"
    resolve_relations: "cases.featured,cases.cases,products.products,materials.items,product_series.categories,scroll_bar.categories,scroll_bar.materials,scroll_bar.products"
  ) {
    id
    uuid
    name
    slug
    full_slug
    translated_slugs {
      lang
      name
      path
    }
    content {
      body
      component
      disable_breadcrumbs
      seo
      inactive_regions
      _uid
      _editable
    }
  }
}
    `;
export const DynamicPagesDocument = gql`
    query dynamicPages {
  DynamicpageItems {
    items {
      id
      uuid
      name
      slug
      full_slug
      translated_slugs {
        lang
        name
        path
      }
      content {
        body
        component
        _uid
        _editable
        seo
      }
    }
  }
}
    `;
export const FiltersDocument = gql`
    query filters {
  FilteritemItems {
    items {
      content {
        label
        value
        key
      }
      uuid
    }
  }
}
    `;
export const FooterDocument = gql`
    query footer($slug: ID!) {
  FooterItem(id: $slug, resolve_links: "url") {
    published_at
    content {
      newsletter_title
      newsletter_description
      newsletter_placeholder
      newsletterHubspotPortalId
      newsletterHubspotFormId
      link_list
      hideForPages {
        slug
      }
      social_title
      social_links
      bottom_links
      bottom_text
    }
    uuid
  }
}
    `;
export const HomeDocument = gql`
    query home($slug: ID!) {
  HomepageItem(
    id: $slug
    resolve_links: "url"
    resolve_relations: "cases.featured,cases.cases,applications.items,product_series.categories,scroll_bar.categories,scroll_bar.materials,scroll_bar.products"
  ) {
    uuid
    id
    name
    slug
    content {
      hero
      body
      component
      _uid
      _editable
    }
  }
}
    `;
export const LanguageModalDocument = gql`
    query languageModal($slug: ID!) {
  LangmodalItem(id: $slug, resolve_links: "url") {
    published_at
    uuid
    content {
      modal_title
      modal_description
      modal_button
      drawer_title
      drawer_button
      region_input
      language_input
    }
  }
}
    `;
export const MaterialsDocument = gql`
    query materials {
  DynamicmaterialpageItems(starts_with: "materials/") {
    items {
      id
      uuid
      name
      slug
      full_slug
      translated_slugs {
        lang
        name
        path
      }
      content {
        _editable
        _uid
        title
        subtitle
        summary
        image {
          alt
          filename
        }
      }
    }
  }
}
    `;
export const NavigationDocument = gql`
    query navigation($slug: ID!) {
  NavigationItem(
    id: $slug
    resolve_links: "url"
    resolve_relations: "galleryItem.category,products_item.categories"
  ) {
    content {
      menuItems
      quotation_button
      hideForPages {
        slug
      }
    }
    published_at
    uuid
  }
}
    `;
export const PageByIdDocument = gql`
    query pageById($slug: ID!) {
  PageItem(
    id: $slug
    resolve_links: "url"
    resolve_relations: "cases.featured,cases.cases,applications.items,product_series.categories,scroll_bar.categories,scroll_bar.materials,scroll_bar.products"
  ) {
    id
    uuid
    name
    slug
    full_slug
    translated_slugs {
      lang
      name
      path
    }
    content {
      body
      component
      enable_filters
      _uid
      _editable
      seo
    }
  }
}
    `;
export const ProductSerieDocument = gql`
    query productSerie($slug: ID!) {
  ProductseriesItem(
    id: $slug
    resolve_links: "url"
    resolve_relations: "category.CategoryItem,cases.featured,cases.cases,applications.items,product_series.categories,scroll_bar.categories,scroll_bar.materials,scroll_bar.products"
  ) {
    id
    uuid
    name
    slug
    content {
      name
      category {
        slug
      }
      description
      image {
        alt
        filename
        focus
      }
      hide_configurator_and_downloads
      usps
      awards {
        alt
        filename
        focus
      }
      introduction_image_on_right
      links
      body
      body_top
      downloads
      component
      _uid
      _editable
      seo
      product
    }
  }
}
    `;
export const ProductSeriesDocument = gql`
    query productSeries($starts_with: String, $filter_query: JsonScalar) {
  ProductseriesItems(starts_with: $starts_with, filter_query: $filter_query) {
    items {
      id
      uuid
      name
      slug
      full_slug
      translated_slugs {
        lang
        name
        path
      }
      content {
        _uid
        _editable
        name
        category {
          name
          slug
          uuid
        }
        description
        hide_configurator_and_downloads
        summary
        image {
          alt
          filename
          focus
        }
      }
    }
  }
}
    `;
export const ProductSeriesOverviewDocument = gql`
    query productSeriesOverview($slug: ID!) {
  OverviewItem(
    id: $slug
    resolve_links: "url"
    resolve_relations: "cases.featured,cases.cases,applications.items,product_series.categories,scroll_bar.categories,scroll_bar.materials,scroll_bar.products"
  ) {
    id
    uuid
    name
    slug
    content {
      title
      bold_title
      break_bold
      variant
      description
      image {
        alt
        filename
        focus
      }
      body
      body_top
      disable_sliders
      discover
      materials
      textures
      component
      _uid
      _editable
      seo
    }
  }
}
    `;
export const ShopModalDocument = gql`
    query shopModal($slug: ID = "en/settings/shop-modal") {
  ShopmodalItem(id: $slug) {
    content {
      title
      description
      button
    }
  }
}
    `;
export const TexturesDocument = gql`
    query textures {
  DynamicmaterialpageItems(starts_with: "textures/") {
    items {
      id
      uuid
      name
      full_slug
      translated_slugs {
        lang
        name
        path
      }
      content {
        _editable
        _uid
        title
        summary
        image {
          alt
          filename
        }
      }
    }
  }
}
    `;
export const TranslationsDocument = gql`
    query translations($slug: ID!) {
  TranslationsItem(id: $slug) {
    uuid
    content {
      language
      region
      applications
      cases
      products
      read_more
      save_settings
      start_configurator
      show_product_downloads
      discover
      less_details
      more_details
      configure_this_model
      configure_your_product
      here
      discover_all
      no_results
      load_more_projects
      case_studies
      clear_filters
      compatible_series
      set_filters
      unauthorized
      unknown
      not_found
      back
      server_error
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    applicationsPage(variables: ApplicationsPageQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ApplicationsPageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ApplicationsPageQuery>(ApplicationsPageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'applicationsPage', 'query');
    },
    casepageItem(variables: CasepageItemQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CasepageItemQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CasepageItemQuery>(CasepageItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'casepageItem', 'query');
    },
    casepageItems(variables?: CasepageItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CasepageItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CasepageItemsQuery>(CasepageItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'casepageItems', 'query');
    },
    categories(variables: CategoriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CategoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CategoriesQuery>(CategoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'categories', 'query');
    },
    categoryItem(variables: CategoryItemQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CategoryItemQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CategoryItemQuery>(CategoryItemDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'categoryItem', 'query');
    },
    contactSection(variables: ContactSectionQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ContactSectionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContactSectionQuery>(ContactSectionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'contactSection', 'query');
    },
    cookieBar(variables: CookieBarQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CookieBarQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<CookieBarQuery>(CookieBarDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cookieBar', 'query');
    },
    dynamicMaterialPageById(variables: DynamicMaterialPageByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DynamicMaterialPageByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DynamicMaterialPageByIdQuery>(DynamicMaterialPageByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'dynamicMaterialPageById', 'query');
    },
    dynamicPageById(variables: DynamicPageByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DynamicPageByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DynamicPageByIdQuery>(DynamicPageByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'dynamicPageById', 'query');
    },
    dynamicPages(variables?: DynamicPagesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DynamicPagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DynamicPagesQuery>(DynamicPagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'dynamicPages', 'query');
    },
    filters(variables?: FiltersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FiltersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FiltersQuery>(FiltersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'filters', 'query');
    },
    footer(variables: FooterQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<FooterQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<FooterQuery>(FooterDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'footer', 'query');
    },
    home(variables: HomeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<HomeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<HomeQuery>(HomeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'home', 'query');
    },
    languageModal(variables: LanguageModalQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LanguageModalQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<LanguageModalQuery>(LanguageModalDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'languageModal', 'query');
    },
    materials(variables?: MaterialsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MaterialsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MaterialsQuery>(MaterialsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'materials', 'query');
    },
    navigation(variables: NavigationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<NavigationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<NavigationQuery>(NavigationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'navigation', 'query');
    },
    pageById(variables: PageByIdQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<PageByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PageByIdQuery>(PageByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'pageById', 'query');
    },
    productSerie(variables: ProductSerieQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductSerieQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductSerieQuery>(ProductSerieDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'productSerie', 'query');
    },
    productSeries(variables?: ProductSeriesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductSeriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductSeriesQuery>(ProductSeriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'productSeries', 'query');
    },
    productSeriesOverview(variables: ProductSeriesOverviewQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductSeriesOverviewQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductSeriesOverviewQuery>(ProductSeriesOverviewDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'productSeriesOverview', 'query');
    },
    shopModal(variables?: ShopModalQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ShopModalQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ShopModalQuery>(ShopModalDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'shopModal', 'query');
    },
    textures(variables?: TexturesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TexturesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TexturesQuery>(TexturesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'textures', 'query');
    },
    translations(variables: TranslationsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TranslationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TranslationsQuery>(TranslationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'translations', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;