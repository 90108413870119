import React from 'react';
import { withPasswordProtect } from '@storyofams/next-password-protect';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import { Providers } from '~components';
import { seo } from '~config';
import CSSreset from '~styles/CSSreset';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  import('~lib/sentry').then((m) => m.initSentry());
}

const MyApp = ({ Component, pageProps }) => {
  const { locale } = useRouter();

  return (
    <Providers pageProps={pageProps} locale={locale}>
      <CSSreset />
      <DefaultSeo {...seo} />
      <Component {...pageProps} />
    </Providers>
  );
};

export default process.env.PASSWORD_PROTECT
  ? withPasswordProtect(MyApp, {
      loginComponentProps: {
        buttonBackgroundColor: '#010406',
        buttonColor: '#fff',
        logo: '/images/logo.png',
      },
    })
  : MyApp;
