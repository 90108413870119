import { Box, system, SystemProps } from '@storyofams/react-ui';
import styled from 'styled-components';
import { variant, ResponsiveValue } from 'styled-system';

export const headingVariants = {
  hero: {
    fontSize: [5, 8],
    lineHeight: 'heading',
  },
  h1: {
    fontSize: ['28px', '59px'],
    lineHeight: ['high', 'heading'],
  },
  h2: {
    lineHeight: 'heading',
    fontSize: [3, 5],
  },
  h3: {
    fontSize: [2.5, '37px'],
    lineHeight: ['medium', 'heading'],
  },
  h4: {
    fontSize: [2, '23px'],
    fontWeight: 'bold',
    lineHeight: 'high',
  },
  h5: {
    lineHeight: ['medium', 'heading'],
    fontSize: [1.75, 2.25],
  },
  h6: {
    fontSize: [1.5, 1.75],
    lineHeight: ['medium', 'heading'],
  },
};

export type CustomProps = {
  variant?:
    | ResponsiveValue<keyof typeof headingVariants>
    | keyof typeof headingVariants;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
} & SystemProps;

export const Heading = styled(Box).attrs((props: CustomProps) => {
  return {as: props?.as ?? 'h1',}
})<CustomProps>`
  letter-spacing: -0.01em;
  font-family: ${(p) => p.theme.fonts.body};
  line-height: ${(p) => p.theme.lineHeights.normal};
  color: ${(p) => p.theme.colors.grey900};

  ${variant({ variants: headingVariants })}
  ${system};
`;
