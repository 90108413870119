import React from 'react';
import { useId } from 'react-id-generator';
import styled from 'styled-components';

import { Text } from '~components/common/Text';

const StyledRadio = styled.input`
  appearance: none;
  outline: none;
  border: none;

  position: relative;

  width: 20px;
  height: 20px;

  margin-right: ${({ theme }) => theme.space[1]}px;

  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.grey900};
  background-color: ${({ theme }) => theme.colors.grey100};

  transition: border-shadow 0.18s ease-in-out,
    background-color 0.18s ease-in-out, 0.18s box-shadow ease-in-out;

  &:checked {
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: ${({ theme }) => theme.colors.grey900};
      border: 1px solid ${({ theme }) => theme.colors.grey900};

      width: 12px;
      height: 12px;
      border-radius: 50%;

      transition: background-color 0.18s ease-in-out;
    }
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.grey300};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
`;

export const Radio = ({ value, label, disabled, ...rest }) => {
  const autoId = useId();
  const id = `radio-button-${autoId}`;

  return (
    <Text
      as="label"
      variant="checkboxLabel"
      htmlFor={id}
      key={id}
      display="flex"
      alignItems="center"
      opacity={disabled ? 0.25 : 1}
      cursor={disabled ? 'not-allowed' : 'pointer'}
    >
      <StyledRadio
        id={id}
        value={value}
        type="radio"
        disabled={disabled}
        {...rest}
      />
      {label}
    </Text>
  );
};
