import { useEffect, useRef, useState } from "react";

export const useHover = () => {
  const [value, setValue] = useState(false);
  const [timedOutValue, setTimedOutValue] = useState(false);
  
  const ref = useRef(null);
  
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  
  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('focusin', handleMouseOver);
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('focusout', handleMouseOut);
      node.addEventListener('mouseout', handleMouseOut);
      
      return () => {
        node.removeEventListener('focusin', handleMouseOver);
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('focusout', handleMouseOut);
        node.removeEventListener('mouseout', handleMouseOut);
      };
    }
  }, [ref.current]);
  
  useEffect(() => {
    if (value) {
      const timer = setTimeout(() => {
        setTimedOutValue(true);
      }, 320);
      
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setTimedOutValue(false);
      }, 48);
      
      return () => clearTimeout(timer);
    }
  }, [value]);
  
  return [ref, timedOutValue];
}
