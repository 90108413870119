import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Flex, Icon, SystemProps } from '@storyofams/react-ui';

import { useRouter } from 'next/router';
import {
  NavigationItem,
  FooterItem,
  LangmodalItem,
  CookiebarItem,
  CasepageItemsQuery,
  TranslationsQuery,
  ShopModalQuery,
} from '~lib/storyblok/sdk';

import { Button } from '../Button';
import { Footer } from '../Footer';
import { Navigation } from '../Navigation';
import { LanguageSelector } from '../Navigation/components/LanguageSelector';
import { NavigationMegaLink } from '../Navigation/components/NavigationMegaLink';
import { NavigationPrimary } from '../Navigation/components/NavigationPrimary';
import { CookieBar } from './CookieBar';
import { LanguageDrawer } from './LanguageDrawer';
import { LanguageModal } from './LanguageModal';
import { Basket } from '../Icons';
import { NavigationLink } from '../Navigation/components/NavigationLink';
import { useTranslations } from '~context';
import { useQuotation } from '~context/quotation';
import { ShopModal } from './ShopModal';

const LOCAL_STORAGE_ACCEPTED_COOKIES = 'LOCAL_STORAGE_ACCEPTED_COOKIES';

interface LayoutProps extends SystemProps {
  children: ReactNode;
  navigation: NavigationItem;
  footer: FooterItem;
  languageModal: LangmodalItem;
  cookieBar: CookiebarItem;
  shopModal: ShopModalQuery['ShopmodalItem']
  latestCases: CasepageItemsQuery['CasepageItems'];
  translations: TranslationsQuery['TranslationsItem'];
}

export const Layout = ({
  children,
  footer,
  navigation,
  languageModal,
  cookieBar,
  latestCases,
  shopModal,
  translations,
  ...props
}: LayoutProps) => {
  const { setLanguageSelectorOpen, saveSettings, languageSelectorOpen, showSettingsModal } = useTranslations();
  const [showCookieBar, setShowCookieBar] = useState(false);
  const { quotation } = useQuotation();
  const { hideForPages } = navigation?.content;
  const [showShopModal, setShowShopModal] = useState(false);
  const router = useRouter();
  let hideNavigation = false;
  
  hideForPages?.map((item => {
    if (
      typeof router.query.slug === 'string' &&
      typeof item.slug === 'string' &&
      item.slug !== 'undefined' &&
      router.query.slug !== 'undefined' &&
      router.query.slug === item.slug
    ) {
      hideNavigation = true;
    }
  }))

  const acceptCookies = () => {
    localStorage.setItem(LOCAL_STORAGE_ACCEPTED_COOKIES, 'true');
    setShowCookieBar(false);
  };
  
  const latestCasesList = useMemo(
    () =>
      latestCases?.items?.map((item) => {
        return {
          title: item?.content?.case?.[0]?.title,
          image: item?.content?.case?.[0]?.thumbnail,
          description: item?.content?.case?.[0]?.summary,
          linkText: translations?.content?.read_more,
          link: item?.full_slug,
          style: 'list',
        };
      }),
    [latestCases],
  );
  
  const menuItems = useMemo(
    () =>
      navigation?.content?.menuItems?.map((item) => {
        switch (item.component) {
          case 'cases_item':
            return { ...item, link: '/cases', list: latestCasesList };
          
          case 'products_item':
            return {
              ...item,
              link: '/product-series',
              gallery: item?.categories?.map((category) => ({
                title: category?.content?.name,
                image: category?.content?.image,
                link: `/product-series#${category?.slug}`,
              })),
            };
          
          default:
            return item;
        }
      }),
    [navigation?.content?.menuItems],
  );
  
  return (
    <>
      <Flex flexDirection="column" width="100%">
        <Navigation>
          <NavigationPrimary disableLink={hideNavigation}>
            <Flex
              flex={[0, 0, 0, 0, 1]}
              justifyContent={[
                'flex-start',
                'flex-start',
                'flex-start',
                'flex-start',
                'space-between',
              ]}
              alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']}
              height="100%"
              pl={[3, 3, 3, 3, 3]}
              pr={[3, 3, 3, 3, 0]}
              pt={[5, 5, 5, 5, 0]}
              pb={[5, 5, 5, 5, 0]}
              flexDirection={['column', 'column', 'column', 'column', 'row']}
              gap={[5, 5, 5, 5, 2]}
            >
              {hideNavigation ? null :
                <Flex
                  gap={[3, 3, 3, 3, 4]}
                  flexDirection={['column', 'column', 'column', 'column', 'row']}
                  alignItems={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center',
                  ]}
                  justifyContent={[
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'flex-start',
                    'center',
                  ]}
                  height={['auto', 'auto', 'auto', 'auto', '100%']}
                  width={['100%', '100%', '100%', '100%', 'auto']}
                  fontSize={[2, 2, 2, 2, 1.75]}
                  lineHeight="high"
                  flex={[0, 0, 0, 0, 1]}
                >
                  {menuItems?.map(({ _uid, ...item }) => (
                    <NavigationMegaLink key={_uid} {...item} />
                  ))}
                </Flex>
              }
              <Flex
                ml={[0, 0, 0, 0, "auto"]}
                alignItems={["start", "start", "start", "start", "center"]}
                position="relative"
                gap={[1, 1, 1, 1, 4]}
                flexDirection={["column", "column", "column", "column", "row"]}
                width={["100%", "100%", "100%", "100%", "auto"]}
              >
                {!hideNavigation && (<>
                  <Button 
                    variant="secondary" 
                    href="/quotation" 
                    display={["flex", "flex", "flex", "flex", "none"]}
                    width={"100%"}
                  >
                    {navigation.content.quotation_button}
                  </Button>
                  <Flex gap="16px" mt={[4, 4, 4, 4, 2.5]} mb={[0, 0, 0, 0, 2.5]}>
                    <NavigationLink 
                      link={"https://mycreation.lighting.philips.com"} 
                      onClick={(e) => {
                        e.preventDefault();
                        setShowShopModal(true);
                      }}
                    >shop</NavigationLink>
                    <NavigationLink link="" active={true}>professional</NavigationLink>
                  </Flex>
                  <LanguageSelector onClick={() => setLanguageSelectorOpen(true)}/>
                  <Button
                    variant="unstyled"
                    to="/quotation"
                    color={"white"}
                    display={["none", "none", "none", "none", "block"]}
                    fontSize="24px"
                    position="relative"
                    height="32px"
                    width="32px"
                  >
                    <Icon icon={Basket}/>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      position="absolute"
                      right={0}
                      top={0}
                      backgroundColor="red"
                      width={"16px"}
                      height={"16px"}
                      borderRadius={"100%"}
                      color="white"
                      fontSize={"12px"}
                      fontWeight="bold"
                    >
                      {quotation}
                    </Box>
                  </Button>
                </>)}
              </Flex>
            </Flex>
          </NavigationPrimary>
        </Navigation>
        
        <Box
          as="main"
          flex="1"
          width="100%"
          minHeight={[
            'calc(100vh - 40px)',
            'calc(100vh - 40px)',
            'calc(100vh - 40px)',
            'calc(100vh - 40px)',
            'calc(100vh - 80px)',
          ]}
          {...props}
        >
          {children}
        </Box>
        
        <Footer
          {...(footer?.content || {})}
          setLanguageSelectorOpen={setLanguageSelectorOpen}
        />
      </Flex>
      
      <LanguageDrawer
        {...languageModal?.content}
        isOpen={languageSelectorOpen}
        setIsOpen={setLanguageSelectorOpen}
        saveSettings={saveSettings}
      />

      <LanguageModal
        {...languageModal?.content}
        isOpen={showSettingsModal}
        saveSettings={saveSettings}
      />
      
      <CookieBar
        {...cookieBar?.content}
        acceptCookies={acceptCookies}
        showCookieBar={showCookieBar}
      />

      <ShopModal
        isOpen={showShopModal}
        onClose={() => setShowShopModal(false)}
        description={""}
        button={""}
        {...shopModal?.content}
      />
    </>
  );
};
