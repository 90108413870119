import { Box, Flex, system } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';
import styled from 'styled-components';
import { Button, Heading, HoverArea, ScaleOnHover } from '~components';

export const TruncatedText = styled.p<{
  lineClamp?: number;
  maxWidth?: string;
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${(p) => p.lineClamp || 3};
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: ${(p) => p.theme.colors.grey700};
  max-width: 296px;

  ${system}
`;

export const ListItem = ({ title, image, description, link, linkText }) => {
  return (
    <HoverArea>
      <Flex width="100%" gap={[1.5, 1.5, 1.5, 2]} alignItems="center">
        <Box
          width={[80, 80, 80, 120]}
          height={[80, 80, 80, 120]}
          flexShrink={0}
          overflow="hidden"
        >
          <ScaleOnHover>
            <Image
              alt={image?.alt}
              src={image?.filename}
              fluid={250}
              width="100%"
              height="100%"
            />
          </ScaleOnHover>
        </Box>

        <Flex flexDirection="column" gap={[0.25, 0.25, 0.25, 1.5]}>
          <Heading
            variant="h6"
            as="h6"
            color="secondary900"
            fontWeight="bold"
            fontSize={[1.5, 1.5, 1.5, 1.75]}
          >
            {title}
          </Heading>

          <TruncatedText>{description}</TruncatedText>

          <Button
            display={[
              'none !important',
              'none !important',
              'none !important',
              'flex !important',
            ]}
            variant="link"
            fontSize={1.75}
          >
            {linkText}
          </Button>
        </Flex>
      </Flex>
    </HoverArea>
  );
};
