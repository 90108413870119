import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';

import { Select, SelectProps } from '../Select';
import { useTranslations } from '~context';

const messages = defineMessages({
  bg: 'Bulgarian',
  da: 'Danish',
  de: 'German',
  en: 'English',
  es: 'Spanish',
  fi: 'Finnish',
  fr: 'French',
  cs: 'Czech',
  id: 'Bahasa Indonesia',
  it: 'Italian',
  nl: 'Dutch',
  no: 'Norwegian',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  ru: 'Russian',
  sv: 'Swedish',
  ccm: 'Colombia, Central America, Mexico',
  acp: 'Argentina, Chile, Peru',
});

type LanguageSwitchProps = SelectProps;

export const LanguageSwitch = ({ label, ...props }: LanguageSwitchProps) => {
  const { locale: routerLocale } = useRouter();
  const { formatMessage } = useIntl();
  const locale = (routerLocale || process.env.DEFAULT_LOCALE)?.toLowerCase()?.split('-')[0];
  const { language, setLanguage, regionalLanguages: languageOptions, region } = useTranslations();

  const options = useMemo(() => {
    return languageOptions.map(
      (value) => {
        return {
          value,
          label: formatMessage(messages[value]) + (region === 'la' && value === 'es' ? ` (${formatMessage(messages['acp'])})` : '')
        }
      }
    ).concat(region === 'la' ? [{
      value: 'ccm',
      label: `${formatMessage(messages['es'])} (${formatMessage(messages['ccm'])})`,
    }] : [])
    .sort((a, b) => a.label.localeCompare(b.label))
  }, [formatMessage, messages, languageOptions])

  return (
    <Select
      label={label}
      options={options}
      value={language}
      isSearchable={false}
      isDisabled={options.length === 1}
      defaultValue={locale}
      onChange={(val: { value: string}) => {
        setLanguage(val.value)}
      }
      {...props}
    />
  );
};
