import { DOMAttributes, FC } from 'react';
import { css, SystemProps } from '@storyofams/react-ui';
import { Button } from '~components';

interface NavigationLinkProps extends SystemProps {
  link: string;
  active?: boolean;
  large?: boolean;
  onClick?: DOMAttributes<HTMLButtonElement>["onClick"];
}

export const NavigationLink: FC<NavigationLinkProps> = ({
  link,
  active,
  large,
  children,
  ...props
}) => {
  return (
    <Button
      // @ts-ignore
      forwardedAs="a" // this doesn't provide the typing but relays the props
      // as="a" // this provides the typing but fails to relay the props
      variant="unstyled"
      to={link}
      display="flex"
      py={large ? "28px" : "8px"}
      pb={large ? "28px" : ["16px", "16px", "16px", "16px", "8px"]}
      width={['100%', '100%', '100%', '100%', 'auto']}
      fontSize={"14px"}
      fontWeight="500"
      lineHeight={['normal', 'normal', 'normal', 'normal', 'high']}
      alignItems="center"
      color={["grey800", "grey800", "grey800", "grey800", "white"]}
      css={css({
        cursor: 'pointer',
        '&&': {
          justifyContent: ['space-between', 'space-between', 'center'],
        },
        '&:after': {
          content: "''",
          position: 'absolute',
          left: 0,
          right: 0,
          width: active ? '100%' : 0,
          bottom: '30px',
          height: '3px',
          backgroundColor: ['grey900', 'grey900', 'grey900', 'grey900', 'white'],
          transition: 'width 0.2s ease-in-out, opacity 0.2s ease-in-out',
          opacity: active ? 1 : 0,
        },
      })}
      {...props}
    >
      {children}
    </Button>
  );
};
