import { ReactNode } from 'react';
import { Box, Flex, Icon, SystemProps } from '@storyofams/react-ui';
import { Image } from '@storyofams/storyblok-toolkit';

import { FixedRatio, Button, LegacyContainer } from '~components';
import { ArrowLeft } from '~components/common/Icons';

export const Header = ({
  title,
  subtitle,
  img,
  backTo,
  textProps,
  children,
  ratio,
  ...props
}: {
  title: string;
  subtitle?: string;
  img: any;
  backTo?: string;
  textProps?: SystemProps;
  children?: ReactNode;
  ratio?: any[];
} & SystemProps) => {
  return (
    <FixedRatio
      ratio={
        ratio || [
          [375, 240],
          [375, 180],
          [375, 160],
          [1440, 440],
        ]
      }
      overflow="hidden"
      maxHeight={640}
    >
      <LegacyContainer space={0} height="100%" pt={0} pb={0}>
        <Flex
          flexDirection="column"
          py={[3, 3, 4]}
          width="100%"
          height="100%"
          justifyContent="center"
          {...props}
        >
          {backTo && (
            <Button
              to={backTo}
              variant="unstyled"
              display="flex"
              justifyContent="center !important"
              alignItems="center"
              mb={[3, 3, 3, 6]}
              size={[32, 32, 48]}
              bg="white"
              borderRadius="full"
              flexShrink={0}
            >
              <Icon icon={ArrowLeft} fontSize={[3, 3, 4]} />
            </Button>
          )}

          {title && (
            <Flex as="h1" fontSize={[4, 4, 8, 'heading']} {...textProps}>
              {title}
            </Flex>
          )}

          {children}
        </Flex>

        <Box
          position="absolute"
          top={0}
          left={0}
          zIndex={-1}
          width="100%"
          height="100%"
          css={{
            '.storyblok-image-wrapper': { width: '100%', height: '100%' },
          }}
        >
          <Image
            alt={img?.alt || ''}
            src={img?.filename}
            fluid={1200}
            fit="cover"
          />
        </Box>
      </LegacyContainer>
    </FixedRatio>
  );
};
