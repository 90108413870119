const createStoryblokThrottle = (msInterval = 1000, limit = 100) => {
  const interval = msInterval / limit;
  let lastDoneTime = 0;
  return async (fn: Function, points = 2) => {
    const currentDoneTime = lastDoneTime + interval * points;
    lastDoneTime =
      currentDoneTime > new Date().getTime()
        ? currentDoneTime
        : new Date().getTime();
    let waitTime = currentDoneTime - new Date().getTime();
    if (waitTime < 0) waitTime = 0;

    return (await new Promise((resolve) => {
      setTimeout(async () => {
        resolve(await fn(waitTime));
      }, waitTime);
    })) as Promise<any>;
  };
};

export const storyblokThrottle = createStoryblokThrottle(
  1000,
  process.env.NEXT_PUBLIC_STORYBLOK_CALL_LIMIT as unknown as number
);