import { Flex, Icon } from '@storyofams/react-ui';

import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Button } from '~components';
import { ChevronDown, Globe } from '../../Icons';

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  background-color: ${(p) => p.theme.colors.primary};
  height: ${(p) => p.theme.space[5]}px;
  margin-top: ${(p) => p.theme.space[2]}px;
  border-radius: ${(p) => p.theme.radii.full};

  @media (min-width: 1280px) {
    background: transparent;
    height: ${(p) => p.theme.space[4]}px;
    margin: 0;
  }
`;

export const LanguageSelector = ({ onClick, ...props }) => {
  const { defaultLocale, locale } = useRouter();

  return (
    <Wrapper {...props}>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        height="100%"
        px={[2, 2, 3]}
      >
        <Button
          variant="unstyled"
          color="white"
          alignItems="center"
          fontSize={2.25}
          lineHeight="150%"
          onClick={onClick}
          gap={1}
        >
          {(locale || defaultLocale).toUpperCase()}
          <Icon icon={ChevronDown} fontSize={3} color="white" mr={0.75} /> 
        </Button>
      </Flex>
    </Wrapper>
  );
};
