import React from 'react';
import { SystemProps } from '@storyofams/react-ui';
import hoistNonReactStatics from 'hoist-non-react-statics';

import { Layout } from '~components';
import { getDisplayName } from './getDisplayName';

export function withLayout(
  Component: any,
  layoutProps?: SystemProps & {
    backgroundImage?: string;
    backgroundRepeat?: string;
    backgroundSize?: string;
  },
) {
  const LayoutComponent = ({
    footer = {},
    navigation,
    languageModal,
    cookieBar,
    shopModal,
    latestCases,
    ...props
  }) => {
    return (
      <Layout
        {...layoutProps}
        footer={footer}
        navigation={navigation}
        languageModal={languageModal}
        cookieBar={cookieBar}
        shopModal={shopModal}
        latestCases={latestCases}
        translations={props?.translations}
      >
        <Component {...props} />
      </Layout>
    );
  };

  hoistNonReactStatics(LayoutComponent, Component);

  LayoutComponent.displayName = `withLayout(${getDisplayName(Component)})`;

  return LayoutComponent;
}
