const siteTitle = 'Philips MyCreation Professional Lighting Solutions';
export const seo = {
  openGraph: {
    type: "website",
    url: "https://pro.mycreation.lighting.philips.com/",
    site_name: siteTitle,
  },
  twitter: {
    handle: "@Philips:MyCreation",
    cardType: "summary_large_image",
  },
  titleTemplate: process.env.NODE_ENV === "development" ? "DEV: %s" : "%s",
};
