import React, { ReactNode } from 'react';
import {
  DialogOverlay as ReachDialogOverlay,
  DialogContent as ReachDialogContent,
} from '@reach/dialog';
import { SystemProps, Icon, system } from '@storyofams/react-ui';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Heading, Text, Button } from '~components';
import { Close } from '~components/common/Icons';

const MotionOverlay = motion(ReachDialogOverlay);
const MotionDialog = motion(ReachDialogContent);

const DialogOverlay = styled(MotionOverlay)<SystemProps>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: ${(p) => p.theme.zIndices.modal};

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.4);
  opacity: 0.75;

  padding: ${(p) => p.theme.space[4]}px;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    padding: ${(p) => p.theme.space[4]}px ${(p) => p.theme.space[2]}px 92px;
  }
`;

const DialogContent = styled(MotionDialog)<{ children?: ReactNode }>`
  flex: 1;
  height: 100%;

  padding: 0;
  margin: 0;
  max-height: 100%;
  background: transparent;
`;

const CloseButton = styled(Button)`
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: color 0.2s;

  &:hover {
    svg {
      color: ${(p) => p.theme.colors.grey500};
    }
  }
`;

const ContentWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 160px);
  max-width: 560px;
  margin: 64px auto;
  padding: ${(p) => p.theme.space[6]}px ${(p) => p.theme.space[5]}px;
  background: ${(p) => p.theme.colors.white};

  ${system}
`;

interface ModalProps extends SystemProps {
  isOpen: boolean;
  close?(): void;
  children: ReactNode;
  ariaLabel?: string;
  title?: string;
  description?: string;
  hideCloseButton?: boolean;
}

export const Modal = ({
  title,
  description,
  children,
  ariaLabel,
  isOpen,
  close,
  hideCloseButton,
  ...props
}: ModalProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <DialogOverlay
          onDismiss={close}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
        >
          <DialogContent
            aria-label={ariaLabel || 'modal'}
            initial={{ y: -10 }}
            animate={{ y: 0 }}
            exit={{ y: -10 }}
          >
            <ContentWrapper
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
              {...props}
            >
              {!!close && !hideCloseButton && (
                <CloseButton
                  variant="unstyled"
                  onClick={close}
                  aria-label="Close modal"
                >
                  <Icon icon={Close} fontSize={3} color="grey500" />
                </CloseButton>
              )}

              {title && (
                <Heading variant={['h4']} as="h2" mb={3}>
                  {title}
                </Heading>
              )}

              {description && (
                <Text mb={4} fontSize={2.25}>
                  {description}
                </Text>
              )}

              {children}
            </ContentWrapper>
          </DialogContent>
        </DialogOverlay>
      )}
    </AnimatePresence>
  );
};
