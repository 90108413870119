import { useEffect, useRef } from "react";

export const useClick = (activeNavItem: any, setActiveNavItem: any, id: string) => {
  const ref = useRef(null);
  
  const handleClick = (e) => {
    const isClickedInside = ref.current && ref.current.contains(e.target);
    const shouldClose = (isClickedInside && activeNavItem === id) || (!isClickedInside && activeNavItem === id);
    const shouldOpen = isClickedInside && activeNavItem !== id;
    
    if (shouldClose) {
      setActiveNavItem(null);
    } else if (shouldOpen) {
      setActiveNavItem(id);
    }
  };
  
  useEffect(() => {
    document.addEventListener('click', handleClick);
    
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [activeNavItem]);
  
  const isClicked = activeNavItem === id;
  
  return [ref, isClicked];
}
