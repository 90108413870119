import { Box, css, SystemProps } from "@storyofams/react-ui";
import { Button } from '~components';
import { MyCreationLogo } from '~components/common/Icons';
import Link from 'next/link';
import { FC } from "react";


interface LogoProps {
  disableLink: boolean;
}


export const Logo: FC<LogoProps> = ({ disableLink }) => {
  return (disableLink ?
      <Box
        position="absolute"
        top={0}
        zIndex={1}
        ml={2}
        css={css({
          "& svg": {
            width: "80px",
            height: "58px",
            "@media (min-width: 480px)": {
              width: "100px",
              height: "74px",
            },
            "@media (min-width: 768px)": {
              width: "146px",
              height: "106px",
            },
          }
        })}
      >
        <MyCreationLogo/>
      </Box> :
      <Button
        as="a"
        variant="unstyled"
        href="/"
        position="absolute"
        top={0}
        zIndex={1}
        ml={2}
        css={css({
          "& svg": {
            width: "80px",
            height: "58px",
            "@media (min-width: 480px)": {
              width: "100px",
              height: "74px",
            },
            "@media (min-width: 768px)": {
              width: "146px",
              height: "106px",
            },
          }
        })}
      >
        <MyCreationLogo/>
      </Button>
  );
};
