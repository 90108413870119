import { forwardRef } from 'react';
import {
  Select as RUISelect,
  InputWrapperProps,
  css,
  Box,
  Icon,
} from '@storyofams/react-ui';

import { Props } from 'react-select';
import { ChevronDown } from '../Icons';

export type SelectOptions = {
  label: string;
  value: string;
}[];

export type SelectProps = {
  id?: string;
  region?: string;
} & InputWrapperProps &
  Props;

const CustomDropdownIndicator = ({ innerRef, innerProps }) => (
  <Icon
    className="dropdown-indicator"
    mr={1.5}
    ref={innerRef}
    icon={ChevronDown}
    fontSize={2.5}
    color="black"
    {...innerProps}
  />
);

export const Select = forwardRef((props: SelectProps, ref) => {
  return (
    <Box
      css={css({
        label: {
          fontSize: 1.5,
          color: 'grey800',
        },
      })}
    >
      <RUISelect
        ref={ref}
        width="100%"
        {...props}
        value={
          typeof props.value === 'string'
            ? // @ts-ignore
              props?.options?.find(
                ({ value }) => value === (props.value as any),
              )
            : props.value
        }
        // @ts-ignore
        defaultValue={props?.options?.find(
          ({ value }) => value === (props.defaultValue as any),
        )}
        components={{
          // @ts-ignore
          DropdownIndicator: CustomDropdownIndicator,
          ...props.components,
        }}
        css={css({
          '.react-select': {
            '&__control': {
              borderRadius: 'xs',
              border: '1px',
              borderColor: '#CED4DA',
              fontSize: 2,
              color: 'black',
              height: 44,
              '&:hover': {
                cursor: 'pointer',
                borderColor: 'secondary900',
              },
              '.dropdown-indicator': {
                transition: 'transform .18s ease-in-out',
              },
              '&--menu-is-open': {
                borderColor: 'secondary900',
                '.dropdown-indicator': {
                  transform: 'rotate(-180deg)',
                },
              },
            },
            '&__single-value': {
              color: 'black',
            },
            '&__value-container': {
              pl: 1.5,
            },
            '&__menu': {
              position: 'relative',
              borderColor: 'transparent',
              '.react-select__option': {
                px: 2,
                py: 1,
                mb: 0.5,
                '&:hover': {
                  cursor: 'pointer',
                },
                '&:hover, &:focus, &--is-selected': {
                  bg: 'grey300',
                },
                '&--is-selected': {
                  bg: 'secondary900',
                  color: 'white',
                },
                '&--is-disabled': {
                  color: 'grey300',
                  cursor: 'not-allowed',
                },
              },
            },
          },
        })}
      />
    </Box>
  );
});
