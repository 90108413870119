import { Button, Flex, Icon, Close, css } from '@storyofams/react-ui';

import { Exclamation, CheckCircle, XCircle } from '../Icons';
import { Text } from '../Text';

type ToastProps = {
  msg: string;
  persistant?: boolean;
  type: 'info' | 'success' | 'error' | 'alert';
  onClose?: () => void;
};

const content = {
  info: {
    icon: Exclamation,
    color: 'secondary',
  },
  success: {
    icon: CheckCircle,
    color: 'success',
  },
  error: {
    icon: XCircle,
    color: 'error',
  },
  alert: {
    icon: Exclamation,
    color: 'warning',
  },
};

export const Toast = ({ msg, type, persistant }: ToastProps) => (
  <Flex
    px={[2.25, 2.25, 3]}
    py={[1.5, 1.5, 3]}
    width="100%"
    boxShadow="nav"
    borderRadius="md"
    position="relative"
    backgroundColor="grey100"
    alignItems="center"
  >
    <Icon
      fontSize={[2.5, 2.5, 3]}
      icon={content[type].icon}
      color={content[type].color}
    />
    <Text variant="s" ml={[1.25, 1.25, 2.25]} maxWidth="100%" lineHeight={1.5}>
      {msg}
    </Text>

    {persistant && (
      <Button css={css({ position: 'absolute' })} top="16px" right="16px">
        <Icon icon={Close} color="grey700" fontSize="8px" />
      </Button>
    )}
  </Flex>
);
