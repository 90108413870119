import React, { CSSProperties } from 'react';
import { InputWrapper, InputWrapperProps, Stack } from '@storyofams/react-ui';
import { pick, omit } from '@styled-system/props';
import { useId } from 'react-id-generator';
import { ResponsiveValue } from 'styled-system';

import { Radio } from '.';

type CSS = CSSProperties;

type RadioGroupProps = {
  id?: string;
  name: string;
  options: { label?: string; value: string | number }[];
  space: ResponsiveValue<CSS['margin']>;
  flexDirection?: ResponsiveValue<CSS['flexDirection']>;
  disabled?: boolean;
} & InputWrapperProps;

export const RadioGroup = ({
  error,
  options,
  space = 1,
  flexDirection = 'column',
  id: initialId,
  label,
  status = undefined,
  statusMessage = undefined,
  disabled = false,
  ...rest
}: RadioGroupProps) => {
  const autoId = useId();
  const id = initialId || `radio-group-${autoId}`;

  return (
    <InputWrapper
      status={status}
      statusMessage={statusMessage}
      error={error}
      label={label}
      id={id}
      {...pick(rest)}
    >
      <Stack space={space} flexDirection={flexDirection} role="radiogroup">
        {options.map((option, i) => (
          <Radio
            value={option.value}
            label={option?.label ?? option?.value}
            key={i}
            disabled={disabled}
            {...omit(rest)}
          />
        ))}
      </Stack>
    </InputWrapper>
  );
};
