import { FC, useState, useRef, useEffect } from 'react';
import { Flex, Box, SystemProps, Icon, css } from "@storyofams/react-ui";

import { Button, LegacyContainer } from '~components';
import { useTranslations } from '~context';
import { getLinkProps } from '~lib';
import { NavigationItem } from '~lib/storyblok/sdk';
import { GalleryTitle, GalleryItem } from './Gallery';
import { ListItem } from './List';
import { NavigationLink } from './NavigationLink';
import { NavigationMegaLinkMobile } from './NavigationMegaLinkMobile';
import { NavLink } from './NavLink';
import { ChevronDown, ChevronUp } from "~components/common/Icons";
import { DropdownItem, DropdownTitle } from "~components/common/Navigation/components/Dropdown";
import { useHover } from "~lib/useHover";
import { useClick } from "~lib/useClick";

export const NavigationMegaLink: FC<
  NavigationItem['content']['menuItems'] & SystemProps
> = ({ galleryTitle, listTitle, dropdownTitle, dropdown, gallery, list, link, title }) => {
  const id = link.id;
  const [hoverRef, isHovered] = useHover();
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [navRefClicked, isNavClicked] = useClick(activeNavItem, setActiveNavItem, id);
  const [isClosed, setIsClosed] = useState(false);
  const { translations } = useTranslations();
  
  const menuItem = {
    title,
    link,
    subItems: [
      {
        subtitle: galleryTitle,
        items: gallery?.map((item) => ({
          ...item,
          style: 'gallery',
        })),
      },
      {
        subtitle: listTitle,
        items: list?.map((item) => ({
          ...item,
          style: 'list',
        })),
      },
      {
        subtitle: dropdownTitle,
        items: dropdown?.map((item) => ({
          ...item,
          style: 'dropdown',
        })),
      },
    ],
  };
  
  return (
    <Flex
      alignItems={['flex-start', 'flex-start', 'flex-start', 'flex-start', 'center']}
      height="100%"
      width={['100%', '100%', '100%', '100%', 'auto']}
    >
      <NavigationMegaLinkMobile menuItem={menuItem}/>
      
      <Box
        position="fixed"
        left="0"
        right="0"
        bottom="0"
        minHeight="100vh"
        bg="rgba(0, 0, 0, 0.75)"
        opacity={0.5}
        ml="0 !important"
        display={
          isHovered && !isClosed && (!!list?.length || !!gallery?.length)
            ? ['none', 'none', 'block']
            : 'none'
        }
      />
      
      <Box
        fontSize={[2, 2, 1.75]}
        height={['auto', 'auto', 'auto', 'auto', '100%']}
        lineHeight="high"
        display={['none', 'none', 'none', 'none', 'flex']}
        ref={hoverRef as any}
      >
        {!dropdown?.length && (
        <NavigationLink active={!!isHovered} link={getLinkProps(link)} large>
          {title}
        </NavigationLink>
        )}
        {!!dropdown?.length && !list?.length && !gallery?.length && (
          <Button
            variant="unstyled"
            color={'white'}
            ref={navRefClicked as any}
            css={css({
              '&:after': {
                content: "''",
                display: 'block',
                position: 'absolute',
                left: 0,
                right: 0,
                width: isNavClicked ? '100%' : 0,
                bottom: '30px',
                height: '3px',
                transition: 'width 0.2s ease-in-out, opacity 0.2s ease-in-out',
                opacity: isNavClicked ? 1 : 0,
                backgroundColor: 'white',
              },
            })}
          >
            {title}
            <Icon
              icon={ChevronDown}
              fontSize={3}
              color="white"
              ml={0.75}
              css={css({
                rotate: isNavClicked ? '180deg' : 0,
                transition: 'rotate 0.2s ease-in-out',
              })}
            />
          </Button>
        )}
        {(!!list?.length || !!gallery?.length) &&
          <Box
            position="absolute"
            top="100px"
            bottom="unset"
            left={0}
            right={0}
            display={
              isHovered && !isClosed && (!!list?.length || !!gallery?.length)
                ? 'block'
                : 'none'
            }
            width="100%"
            boxShadow={'0px 4px 8px rgba(0, 0, 0, 0.04)' as any}
            borderTop="1px"
            borderTopColor="grey200"
            backgroundColor="white"
            zIndex="overlay"
            transition="left .24s ease-out"
          >
            <LegacyContainer
              space={0}
              mx="auto"
              pt={[5, 5, 5, 5, 10]}
              pb={[5, 5, 5, 5, 8]}
              maxWidth="maxWidth"
            >
              <Flex gap={[5, 5, 8, 8, 9]}>
                <Box flex={1}>
                  <Flex
                    mb={[2, 2, 2, 2, 4]}
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <GalleryTitle>{galleryTitle}</GalleryTitle>
                    
                    <Button variant="link" to={getLinkProps(link)} flexShrink={0}>
                      {translations?.discover_all}
                    </Button>
                  </Flex>
                  
                  <Box
                    display="grid"
                    gridRowGap={2}
                    gridColumnGap={3}
                    gridTemplateColumns={
                      !!list?.length ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'
                    }
                  >
                    {gallery?.map((item) => (
                      <NavLink
                        item={item}
                        menuItem={menuItem}
                        key={item?._uid}
                        onClick={() => {
                          setIsClosed(true);
                          setTimeout(() => setIsClosed(false), 1000);
                        }}
                      >
                        <GalleryItem {...item} />
                      </NavLink>
                    ))}
                  </Box>
                </Box>
                
                {!!list?.length && (
                  <Box flex={1}>
                    <GalleryTitle mb={[2, 2, 2, 2, 4]}>{listTitle}</GalleryTitle>
                    
                    <Flex flexDirection="column" gap={2}>
                      {list?.map((item) => (
                        <NavLink
                          item={item}
                          menuItem={menuItem}
                          key={item?._uid}
                          onClick={() => {
                            setIsClosed(true);
                            setTimeout(() => setIsClosed(false), 1000);
                          }}
                        >
                          <ListItem {...item} />
                        </NavLink>
                      ))}
                    </Flex>
                  </Box>
                )}
              </Flex>
            </LegacyContainer>
          </Box>
        }
        {(!!dropdown?.length && !list?.length && !gallery?.length) &&
          <Box
            position="absolute"
            top="100px"
            left={0}
            right={0}
            bottom="unset"
            display={
              isNavClicked && !isClosed && !!dropdown?.length
                ? "block"
                : "none"
            }
            width="100%"
            maxWidth="1000px"
            backgroundColor="white"
            boxShadow={"0px 4px 8px rgba(0, 0, 0, 0.04)" as any}
            mx="auto"
            pt={[5]}
            pb={[5]}
            px={[5]}
            zIndex="overlay"
          >
            <Flex gap={[5, 5, 8, 8, 9]}>
              {!!dropdown?.length && (
                <Box flex={1}>
                  <Flex flexDirection="column" gap={2}>
                    {dropdown?.map((item) => (
                      <NavLink
                        item={item}
                        menuItem={menuItem}
                        key={item?._uid}
                        onClick={() => {
                          setIsClosed(true);
                          setTimeout(() => setIsClosed(false), 1000);
                        }}
                      >
                        <DropdownItem {...item} />
                      </NavLink>
                    ))}
                  </Flex>
                </Box>
              )}
            </Flex>
          </Box>
        }
      </Box>
    </Flex>
  );
};
