import { Flex, Icon } from '@storyofams/react-ui';

import { useRouter } from 'next/router';
import { getLinkProps } from '~lib';
import { FooterItem } from '~lib/storyblok/sdk';

import { Button } from '../Button';
import { Divider } from '../Divider';
import { Globe } from '../Icons';
import { LegacyContainer } from '../Layout/Container';
import { Text } from '../Text';
import { FooterHeading } from './FooterHeading';
import { FooterLink } from './FooterLink';
import { FooterLogo } from './FooterLogo';
import { Newsletter } from './Newsletter';
import { SocialIcons } from './SocialIcons';

export const Footer = ({
  newsletter_title,
  newsletter_description,
  newsletter_placeholder,
  newsletterHubspotFormId,
  newsletterHubspotPortalId,
  link_list,
  social_title,
  social_links,
  bottom_links,
  bottom_text,
  hideForPages,
  setLanguageSelectorOpen
}: FooterItem['content'] & { setLanguageSelectorOpen(val: boolean): void }) => {
  const { defaultLocale, locale, query } = useRouter();
  
  let hideNavigation = false;
  hideForPages?.map((item => {
    if (
      typeof query.slug === 'string' &&
      typeof item.slug === 'string' &&
      item.slug !== 'undefined' &&
      query.slug !== 'undefined' &&
      query.slug === item.slug
    ) {
      hideNavigation = true;
    }
  }))

  return (
    <LegacyContainer
      bg="primary"
      as="footer"
      space={0}
      pt={[8, 8, 15]}
      pb={[3, 3, 4]}
    >
      {/* mobile only */}
      <FooterLogo mb={5} display={{ md: 'none!important' }} />
      <SocialIcons
        social_links={social_links}
        social_title={social_title}
        display={{ md: 'none!important' }}
        justifyContent="center"
        mx="auto"
        mb={60}
      />

      <Newsletter
        mb={[6, 6, 10]}
        newsletter_title={newsletter_title}
        newsletter_description={newsletter_description}
        newsletter_placeholder={newsletter_placeholder}
        newsletterHubspotFormId={newsletterHubspotFormId}
        newsletterHubspotPortalId={newsletterHubspotPortalId}
      />

      <Flex
        flex={1}
        justifyContent="space-between"
        gap={[2, 2, 4]}
        mb={[5, 5, 7]}
        width={['100%', '100%', '100%', 'auto']}
        height={['auto', 'auto', 'auto', '100%']}
        fontSize={2}
        lineHeight="high"
      >
        {/* desktop only */}
        <FooterLogo
          display={[
            'none!important',
            'none!important',
            'none!important',
            'flex!important',
          ]}
          alignSelf="flex-start"
          flex={1}
          justifyContent="flex-start !important"
        />
        {hideNavigation ? null :
          <>
            {link_list?.slice(0, 2).map(({ title, list, _uid }) => (
              <Flex key={_uid} flex={1} flexDirection="column" gap={[3, 3, 4]}>
                <FooterHeading>{title}</FooterHeading>
          
                {list?.map(({ label, url }, key) => (
                  <FooterLink key={`${_uid}-${key}`} to={url}>
                    {label}
                  </FooterLink>
                ))}
              </Flex>
            ))}
      
      
            <Flex
              flex={[1, 1, 2]}
              flexDirection={["column", "column", "row"]}
              justifyContent={["flex-start", "flex-start", "space-between"]}
              gap={[4]}
            >
              {link_list?.slice(2)?.map(({ title, list, _uid }) => (
                <Flex
                  key={_uid}
                  flex={[0, 0, 1]}
                  flexDirection="column"
                  gap={[3, 3, 4]}
                >
                  <FooterHeading>{title}</FooterHeading>
            
                  {list?.map(({ label, url }) => (
                    <FooterLink key={_uid} to={url}>
                      {label}
                    </FooterLink>
                  ))}
                </Flex>
              ))}
            </Flex>
          </>
        }

        {/* desktop only */}
        <SocialIcons
          social_links={social_links}
          social_title={social_title}
          display={[
            'none!important',
            'none!important',
            'none!important',
            'flex!important',
          ]}
          mb={60}
        />
      </Flex>

      <Button
        onClick={() => setLanguageSelectorOpen(true)}
        variant="unstyled"
        alignSelf={['center', 'center', 'center', 'flex-end']}
        justifyContent="center"
        alignItems="center"
        mb={4}
        px={4}
        width={300}
        height={[40, 40, 44]}
        fontSize={[1.5, 1.5, 1.75]}
        fontWeight={['normal', 'normal', 'medium']}
        color="white"
        border="1px"
        borderColor="white"
        borderRadius="4px"
      >
        <Icon icon={Globe} color="white" mr={1.25} />
        Region - {(defaultLocale || locale).toUpperCase()}
      </Button>

      <Divider bg="white" mb={[3, 3, 4]} />
      <Flex
        gap={[3, 3, 4]}
        justifyContent="center"
        flexWrap="wrap"
        mb={[3, 3, 2]}
        pt={1}
      >
        {bottom_links?.map(({ label, url }) => (
          <Button
            key={`bottom-link-${label}`}
            to={getLinkProps(url)}
            variant="unstyled"
            mt={-1}
            color="white"
            fontSize={[1.5, 1.5, 1.75]}
            fontWeight={{ sm: 'medium' }}
          >
            {label}
          </Button>
        ))}
      </Flex>

      <Text
        mx="auto"
        textAlign="center"
        fontSize={[1.5, 1.5, 1.75]}
        fontWeight={{ sm: 'medium' }}
        color="white"
      >
        {bottom_text}
      </Text>
    </LegacyContainer>
  );
};
