import React, { forwardRef, ComponentProps, ReactNode, Ref } from 'react';
import { Icon, SystemProps, Box, Spinner, system } from '@storyofams/react-ui';
import Link from 'next/link';
import type { PolymorphicForwardRefExoticComponent } from 'react-polymorphic-types';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { ArrowRight } from '../Icons';
import { useRouter } from 'next/router';

const _defaultElement = 'button';

const baseStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  px: 4,
  py: 1.5,
  fontSize: [1.5, 1.75],
  lineHeight: 'high',
  height: ['40px', '44px'],
  borderRadius: 'full',
};

export const variants = {
  primary: {
    ...baseStyles,
    bg: 'primary900',
    color: 'white',
    border: 'none',
    borderColor: 'white',
    fontWeight: 'medium',
    transition: 'color 0.25s ease-in-out, background-color 0.25s ease-in-out',

    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        bg: 'primary',
      },
    },

    '&:disabled': {
      opacity: 0.25,
    },
  },
  primaryLight: {
    ...baseStyles,
    bg: 'primary',
    color: 'white',
    border: '1px',
    borderColor: 'primary',
    fontWeight: 'medium',
    transition: 'color 0.25s ease-in-out, background-color 0.25s ease-in-out',
    
    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        bg: 'primary900',
        borderColor: 'white'
      },
    },
    
    '&:disabled': {
      opacity: 0.25,
    },
  },
  secondary: {
    ...baseStyles,
    bg: 'secondary',
    color: 'white',
    fontWeight: 'medium',
    transition: 'color 0.25s ease-in-out, background-color 0.25s ease-in-out',

    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        bg: 'secondary900',
      },
    },

    '&:disabled': {
      opacity: 0.25,
    },
  },
  white: {
    ...baseStyles,
    bg: 'white',
    color: 'primary',
    border: '1px',
    borderColor: 'white',
    fontWeight: 'medium',
    transition: 'color 0.25s ease-in-out, background-color 0.25s ease-in-out',

    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        bg: 'primary',
        color: 'white'
      },
    },

    '&:disabled': {
      opacity: 0.25,
    },
  },
  black: {
    ...baseStyles,
    bg: 'black',
    color: 'white',
    border: '1px',
    borderColor: 'black',
    fontWeight: 'medium',
    transition: 'color 0.25s ease-in-out, background-color 0.25s ease-in-out',

    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        bg: 'white',
        color: 'black'
      },
    },

    '&:disabled': {
      opacity: 0.25,
    },
  },
  configure: {
    ...baseStyles,
    bg: 'tertiary',
    color: 'white',
    border: 'solid 1px',
    borderBottom: 'none',
    borderColor: 'white',
    fontWeight: 'medium',
    transition: 'color 0.25s ease-in-out, background-color 0.25s ease-in-out',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderRadius: '10px',
    
    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        bg: 'tertiary100',
      },
    },
    
    '&:disabled': {
      opacity: 0.25,
    },
  },
  outline: {
    ...baseStyles,
    color: 'white',
    fontWeight: 'medium',
    border: '1px solid',
    borderColor: 'white',
    transition: 'color 0.25s ease-in-out, border-color 0.25s ease-in-out',

    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        color: 'grey500',
        borderColor: 'grey500',
      },
    },
    '&:disabled': {
      color: 'grey900',
      borderColor: 'grey900',
      opacity: 0.25,
    },
  },
  underlined: {
    ...baseStyles,
    color: 'black',
    fontWeight: 'small',
    borderColor: 'white',
    transition: 'color 0.25s ease-in-out, border-color 0.25s ease-in-out',
    padding: 0,
    textDecoration: 'underline',
    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        color: 'grey500',
        borderColor: 'grey500',
      },
    },
    '&:disabled': {
      color: 'grey900',
      borderColor: 'grey900',
      opacity: 0.25,
    },
  },
  link: {
    fontSize: [1.5, 2],
    fontWeight: 'medium',
    color: 'grey900',
    display: 'inline-flex',
    transition: 'color 0.25s ease-in-out',

    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        color: 'secondary900',
      },
    },

    '&:disabled': {
      color: 'grey900',
      opacity: 0.25,
    },
  },
  linkLight: {
    fontSize: [1.5, 2],
    fontWeight: 'medium',
    color: 'white',
    display: 'inline-flex',
    transition: 'color 0.25s ease-in-out',
    
    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        color: 'secondary',
      },
    },
    
    '&:disabled': {
      color: 'grey900',
      opacity: 0.25,
    },
  },
  unstyled: {
    '&:disabled': { cursor: 'not-allowed', color: 'grey500' },
  },
  linkNav: {
    fontSize: [1.5, 2],
    fontWeight: 'normal',
    color: 'grey800',
    display: 'inline-flex',
    transition: 'color 0.25s ease-in-out',
    
    '&:not(:disabled)': {
      '&:hover, &:active, &:focus': {
        color: 'secondary900',
      },
    },
    '&:disabled': {
      color: 'grey900',
      opacity: 0.25,
    },
  },
};

export type ButtonProps = {
  isLoading?: boolean;
  icon?: ComponentProps<typeof Icon>['icon'];
  href?: string;
  rel?: string;
  target?: string;
  to?: string;
  variant?: keyof typeof variants;
  disabled?: boolean;
  children: ReactNode;
  download?: string;
  hideArrow?: boolean;
};

const StyledButton = styled(Box)<Pick<ButtonProps, 'variant'>>`
  position: relative;
  appearance: none;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }

  &[data-is-loading] {
    cursor: wait;
    opacity: 1;
  }

  ${variant({ variants })}
  ${system}
`;

const loadingStyles = {
  position: 'relative' as any,
  disabled: true,
  'data-is-loading': true,
  'aria-disabled': true,
};

export const Button: PolymorphicForwardRefExoticComponent<
  ButtonProps & SystemProps,
  typeof _defaultElement
> = forwardRef(
  ({ children, icon, to, isLoading, ...props }: ButtonProps, ref: Ref<any>) => {
    const { locale } = useRouter();
    const [ lang, region ] = locale.split("-")
    const getLinkProps = (link: string) => {
      if (!link) {
        return {};
      }
      return link.startsWith('http')
        ? {
            target: '_blank',
            rel: 'noopener noreferrer',
          }
        : {};
    };

    const getHref = (link: string) => {
      if (!link) {
        return undefined;
      }
      if (link.startsWith('http')) {
        return link
      } 
      
      // Check if link starts with current language. If so, remove it.
      if (link.split("/")[1] === lang) {
        link = link.split("/").slice(2).join("/");
      } else {
        link = link.split("/").slice(1).join("/");
      }

      // Replace /{region}/ in the middle of the link with /
      link = link.replace(`/${region}/`, "/");

      return `/${locale}/${link}`;
    };

    const content = (
      <>
        {children}


        {!!icon && props.variant !== 'link' && props.variant !== 'white' && props.variant !== 'linkLight' && (
          <Icon fontSize="15px" icon={icon} />
        )}

        {(props.variant === 'link' || props.variant === 'linkLight' || props.variant === 'white')&& !props.hideArrow && (
          <Icon fontSize="15px" icon={ArrowRight} />
        )}
      </>
    );

    return to ? (
      <Link href={getHref(to)} passHref>
        <StyledButton as="a" variant="link"
        gap={"0.5rem"}

        {...props} {...getLinkProps(to)}>
          {content}
        </StyledButton>
      </Link>
    ) : (
      <StyledButton
        as={props?.href ? 'a' : _defaultElement}
        variant={props?.href ? 'link' : 'secondary'}
        display="flex"
        gap={"0.5rem"}
        {...props}
        {...(isLoading ? loadingStyles : {})}
        {...getLinkProps(props?.href)}
        href={getHref(props?.href)}
        ref={ref}
      >
        {isLoading ? (
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Spinner color="inherit" size={18 as any} />
          </Box>
        ) : (
          content
        )}
      </StyledButton>
    );
  },
);
