import { Flex, Icon, SystemProps } from '@storyofams/react-ui';

import { getLinkProps } from '~lib';
import { FooterItem } from '~lib/storyblok/sdk';

import { Button } from '../Button';
import { Heading } from '../Heading';
import {
  Youtube,
  Facebook,
  Instagram,
  Pinterest,
  Twitter,
  LinkedIn,
} from '../Icons';

const socialIcons = {
  instagram: Instagram,
  facebook: Facebook,
  pinterest: Pinterest,
  linkedin: LinkedIn,
  twitter: Twitter,
  youtube: Youtube,
};

export const SocialIcons = ({
  social_title,
  social_links,
  ...props
}: Partial<FooterItem['content']> & SystemProps) => {
  return (
    <Flex
      flexShrink={0}
      flexDirection={['row', 'row', 'column']}
      gap={3}
      {...props}
    >
      <Heading
        variant="h4"
        as="h5"
        display={[
          'none!important',
          'none!important',
          'none!important',
          'flex!important',
        ]}
        fontWeight="bold"
        color="white"
      >
        {social_title}
      </Heading>

      <Flex
        justifyContent={['center', 'center', 'center', 'flex-start']}
        flexWrap="wrap"
        gap={3}
        maxWidth={['auto', 'auto', 'auto', 120]}
      >
        {social_links?.map(({ url, icon }) => (
          <Button
            variant="unstyled"
            to={getLinkProps(url)}
            key={`social-link-${icon}`}
          >
            <Icon icon={socialIcons[icon]} fontSize={3} color="white" />
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};
