import { FC, useEffect, useRef, useState } from "react";
import { Flex, Box, SystemProps, Icon } from "@storyofams/react-ui";
import { useRouter } from 'next/router';
import styled, { keyframes } from 'styled-components';

import { Button } from '~components';
import { useNav, useTranslations } from '~context';
import { getLinkProps } from '~lib';
import { NavigationQuery } from '~lib/storyblok/sdk';
import { GalleryItemMobile, GalleryTitle } from './Gallery';
import { ListItem } from './List';
import { NavLink } from './NavLink';
import { ChevronLeft, ChevronRight } from "~components/common/Icons";
import { DropdownItem, DropdownTitle } from "~components/common/Navigation/components/Dropdown";
import { useClick } from "~lib/useClick";

const fadeIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const Category = styled(Flex)`
  position: fixed;
  top: 64px;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 308px;
  background-color: ${(p) => p.theme.colors.white};
  z-index: ${(p) => p.theme.zIndices.overlay};
  animation: 0.24s ${fadeIn} ease-out, 0.24s;
  transition: opacity 0.24s ease-in;
  overflow-x: auto;
`;

export interface NavigationMegaLinkMobileProps extends SystemProps {
  menuItem: NavigationQuery['NavigationItem']['content']['menuItems']['0'];
}

export const NavigationMegaLinkMobile: FC<NavigationMegaLinkMobileProps> = ({
  menuItem,
}) => {
  const { category, setCategory, mobileMenuOpen, setMobileMenuOpen } = useNav();
  const router = useRouter();
  const { translations } = useTranslations();
  
  const id = menuItem?.link?.id;
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [navRefClicked, isNavClicked] = useClick(activeNavItem, setActiveNavItem, id);
  
  const hasSubItems = menuItem?.subItems?.some((item) => !!item?.items?.length);

  return (
    <Flex
      display={[
        'flex !important',
        'flex !important',
        'flex !important',
        'flex !important',
        'none !important',
      ]}
      width={"100%"}
    >
      
      {!hasSubItems ? (
          <Button
            href={getLinkProps(menuItem?.link)}
            variant="linkNav"
            fontSize={1.75}
            width={"100%"}
            justifyContent={"space-between"}
          >
            {menuItem?.title}
          </Button>
        ) :
        (
          <Button variant="linkNav" fontSize={1.75} width={"100%"}  justifyContent={"space-between"} ref={navRefClicked as any}>
            {menuItem?.title}
            <Icon icon={ChevronRight} fontSize={3} color="black" ml={0.75}/>
          </Button>
        )}

      {category === menuItem?.title && (
        <Box
          position="fixed"
          top={64}
          bottom={0}
          right={category && mobileMenuOpen ? 0 : '-100%'}
          width="100%"
          maxWidth="308px"
          bg="white"
          zIndex="overlay"
          transition="right .24s ease-in-out"
        >
          <Category gap={4} flexDirection="column" px={3} py={5}>
            {menuItem?.subItems.map(({ subtitle, items }, i) =>
              items?.length ? (
                <Box key={`subitems-${i}`}>
                  <GalleryTitle mb={2}>{subtitle}</GalleryTitle>

                  <Flex gap={1} flexDirection="column" mb={2}>
                    {items.map((item) => (
                      <NavLink
                        key={item?.title}
                        item={item}
                        menuItem={menuItem}
                      >
                        {item.style === 'gallery' ? (
                          <GalleryItemMobile {...item} />
                        ) : (
                          <ListItem {...item} />
                        )}
                      </NavLink>
                    ))}
                  </Flex>

                  <Button
                    variant="link"
                    to={getLinkProps(menuItem.link)}
                    flexShrink={0}
                  >
                    {translations?.discover_all}
                  </Button>
                </Box>
              ) : null,
            )}
          </Category>
        </Box>
      )}
      {isNavClicked && (
        <Box
          position="fixed"
          top={64}
          bottom={0}
          right={isNavClicked && mobileMenuOpen ? 0 : '-100%'}
          width="100%"
          maxWidth="308px"
          bg="white"
          zIndex="overlay"
          transition="right .24s ease-in-out"
        >
          <Category gap={4} flexDirection="column" px={3} py={5}>
            {menuItem?.subItems.map(({ subtitle, items }, i) =>
              items?.length ? (
                <Box key={`subitems-${i}`}>
                  <Flex mb={2} justifyContent={'space-between'}>
                    <DropdownTitle>{subtitle}</DropdownTitle>
                    <Button variant="unstyled">
                      <Icon icon={ChevronLeft} fontSize={3} color="black" ml={0.75} />
                    </Button>
                  </Flex>
                  
                  <Flex gap={1} flexDirection="column" mb={2}>
                    {items.map((item) => (
                      <NavLink
                        key={item?.title}
                        item={item}
                        menuItem={menuItem}
                      >
                        {item.style === 'dropdown' ? (
                          <DropdownItem {...item} />
                        ) : null}
                      </NavLink>
                    ))}
                  </Flex>
                </Box>
              ) : null,
            )}
          </Category>
        </Box>
      )}
    </Flex>
  );
};
