import styled from 'styled-components';
import { getLinkProps } from '~lib';
import { Button, ButtonProps } from '../Button';
import { Heading } from '../Heading';

export const FooterLink = ({ children, to, ...props }: ButtonProps) => (
  <Button
    to={getLinkProps(to)}
    variant="unstyled"
    fontSize={[1.5, 1.5, 1.75]}
    fontWeight={['normal', 'normal', 'medium']}
    color="white"
    {...props}
  >
    {children}
  </Button>
);

export const FooterHeading = styled(Heading).attrs({
  variant: 'h4',
  as: 'h5',
  color: 'white',
  fontWeight: 'bold',
})``;
