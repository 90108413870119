import { Button } from '~components';
import { useNav } from '~context';
import { getLinkProps } from '~lib';
import { css } from "@storyofams/react-ui";

export const NavLink = ({
  item,
  menuItem,
  children,
  onClick = undefined,
  ...props
}) => {
  const { setMobileMenuOpen } = useNav();
  const link = getLinkProps(item.link) || getLinkProps(menuItem.link);
  const anchor = item?.anchor || menuItem?.anchor
  const makeBold = item?.makeBold || menuItem?.makeBold

  return (
    <Button
      variant="linkNav"
      to={anchor ? link + anchor : link}
      onClick={() => {
        setMobileMenuOpen(false);
        if (onClick) onClick();
      }}
      css={css({
        fontWeight: makeBold ? 'bold' : 'normal',
      })}
      {...props}
    >
      {children}
    </Button>
  );
};
