import { css } from '@storyofams/react-ui';

import { Button } from '~components/common/Button';
import { Badge } from './Badge';

export type FilterProps = {
  className?: string;
  title: string;
  value: string;
  options: { label: string; value: string }[];
  count?: number;
  onClick?(): void;
};

export const Filter = ({ className, title, count, onClick }: FilterProps) => (
  <Button
    variant={"primaryLight"}
    onClick={onClick}
    className={className}
  >
    {title}
    {!!count && <Badge>{count}</Badge>}
  </Button>
);
