import { Box, Flex, system } from '@storyofams/react-ui';
import { Button, Heading, HoverArea } from "~components";
import styled from "styled-components";

export const DropdownTitle = styled(Heading).attrs({
  as: 'h5',
  variant: 'h5',
  fontWeight: 'bold',
  color: 'black',
  fontSize: [1.75, 1.75, 2.25],
})`
  ${system}
`;


export const DropdownItem = ({ link, linkText }) => {
  return (
    <HoverArea>
      <Flex width="100%" gap={[1.5, 1.5, 1.5, 2]} alignItems="center">
        <Flex flexDirection="column" gap={[0.25, 0.25, 0.25, 1.5]}>
          <Button
            variant="unstyled"
            fontSize={1.75}
            mb={[2, 2, 2, 2, 0]}
          >
            {linkText}
          </Button>
        </Flex>
      </Flex>
    </HoverArea>
  );
};
